import React from "react";
import "./index.css";
import logo from "../../assets/logo.png";
import Facebook from "../../assets/Footer Images/Facebook.png";
import Instagram from "../../assets/Footer Images/Instagram.png";
import LinkedIn from "../../assets/Footer Images/LinkedIn.png";
import Twitter from "../../assets/Footer Images/Twitter.png";
import YouTube from "../../assets/Footer Images/YouTube.png";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaApple } from "react-icons/fa";
import { DiAndroid } from "react-icons/di";
import { themeBlue, themeOrange } from "../../utils/colorTheme";
import app_store from "../../assets/app_store.png";
import play_store from "../../assets/play_store.png";
import { SiFacebook } from "react-icons/si";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { FaLinkedinIn } from "react-icons/fa";
import { VscTwitter } from "react-icons/vsc";
import { RiYoutubeFill } from "react-icons/ri";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";

const Footer = () => {
  const navigate = useNavigate();
  const useFullLinks = [
    {
      name: "Home",
      linkTo: "/",
    },
    {
      name: "Services",
      linkTo: "/services",
    },

    {
      name: "Help Center",
      linkTo: "/help-center",
    },
  ];

  const about = [
    {
      name: "Privacy Policy",
      linkTo: "/privacy-policy",
    },
    {
      name: "Terms and Conditions",
      linkTo: "/terms-and-conditions",
    },
    {
      name: "About Us",
      linkTo: "/about-us",
    },
    // {
    //   name: "FAQ",
    //   linkTo: "/",
    // },
  ];

  const socialIcons = [<SiFacebook />, <BiLogoInstagramAlt />, <VscTwitter />];

  return (
    <div className="footer-container">
      <Container maxWidth="xl">
        <Stack py={5}>
          <Grid container alignItems={"stretch"} spacing={7}>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Stack gap={3} sx={{ height: "100%" }}>
                <Stack>
                  <img alt="logo" src={logo} className="footer-logo" />
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#545454",
                    }}
                  >
                    DoneRightAway is a platform offering quick, reliable home
                    repair and appliance services, connecting customers with
                    skilled professionals.
                  </Typography>
                </Stack>

                <div className="footer_logo_images_container">
                  {socialIcons.map((data, i) => {
                    return (
                      <IconButton
                        key={i}
                        sx={{
                          color: themeOrange,
                          borderRadius: "100%",
                          border: `1px solid ${themeOrange}`,
                        }}
                      >
                        {data}
                      </IconButton>
                    );
                  })}
                </div>
              </Stack>
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Stack sx={{ height: "100%" }} gap={4}>
                <Typography
                  fontSize={"20px"}
                  fontWeight={600}
                  color={themeBlue}
                  mt={2}
                >
                  Quick Links
                </Typography>
                <Stack gap={1} mt={1}>
                  {useFullLinks.map(({ name, linkTo }, i) => {
                    return (
                      <Typography
                        key={i}
                        onClick={() => navigate(linkTo)}
                        sx={{
                          cursor: "pointer",
                          ":hover": {
                            textDecorationLine: "underline",
                          },
                        }}
                        fontSize={"16px"}
                        fontWeight={400}
                        color={"#4D4D4D"}
                      >
                        {name || ""}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Stack sx={{ height: "100%" }} gap={4}>
                <Typography
                  fontSize={"20px"}
                  fontWeight={600}
                  color={themeBlue}
                  mt={2}
                >
                  About
                </Typography>
                <Stack gap={1} mt={1}>
                  {about.map(({ name, linkTo }, i) => {
                    return (
                      <Typography
                        key={i}
                        onClick={() => navigate(linkTo)}
                        sx={{
                          cursor: "pointer",
                          ":hover": {
                            textDecorationLine: "underline",
                          },
                        }}
                        fontSize={"16px"}
                        fontWeight={400}
                        color={"#4D4D4D"}
                      >
                        {name || ""}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
              <Stack sx={{ height: "100%" }} gap={4}>
                <Typography
                  fontSize={"20px"}
                  fontWeight={600}
                  color={themeBlue}
                  mt={2}
                >
                  Contact Us
                </Typography>
                <Stack gap={3} mt={1}>
                  <Stack
                    // onClick={() => navigate("/coming-soon-app")}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    justifycontent={"center"}
                    gap={2}
                  >
                    <HiOutlineEnvelope size={20} sx={{ color: themeBlue }} />
                    <Typography
                      component="a"
                      href="mailto:info@donerightaway.com"
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          textDecorationLine: "underline",
                        },
                      }}
                      fontSize={"16px"}
                      fontWeight={400}
                      color={"#4D4D4D"}
                    >
                      info@donerightaway.com
                    </Typography>
                  </Stack>
                  <Stack
                    // onClick={() => navigate("/coming-soon-app")}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    justifycontent={"center"}
                    gap={2}
                  >
                    <BsTelephone size={20} sx={{ color: themeBlue }} />
                    <Typography
                      component="a"
                      href="tel:+12894004962"
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          textDecorationLine: "underline",
                        },
                      }}
                      fontSize={"16px"}
                      fontWeight={400}
                      color={"#4D4D4D"}
                    >
                      +1 (289) 400-4962
                    </Typography>
                  </Stack>
                  <Stack
                    // onClick={() => navigate("/coming-soon-app")}
                    direction={"row"}
                    justifyContent={"flex-start"}
                    justifycontent={"center"}
                    gap={2}
                  >
                    <HiOutlineLocationMarker
                      size={20}
                      sx={{ color: themeBlue }}
                    />
                    <Typography
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          textDecorationLine: "underline",
                        },
                      }}
                      fontSize={"16px"}
                      fontWeight={400}
                      color={"#4D4D4D"}
                    >
                      Canada
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <div className="last-container">
          <div className="last-text">2024 Done Right Away</div>

          <div
            onClick={() => navigate("/privacy-policy")}
            className="policy-text"
            style={{ cursor: "pointer" }}
          >
            Privacy & Policy
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
