import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { mainRoutes, topBarRoutes } from "../../utils/routeList";
import { useDispatch, useSelector } from "react-redux";
import { asyncStatus, save_tokens_constant } from "../../utils/asyncStatus";
import {
  setAuthState,
  setIdleLoginStatus,
  setIdleRegisterStatus,
  setIdleVendorRegisterStatus,
} from "../../store/slices/user_auth_slice";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { check_auth } from "../../services/authentication";
import { PublicRoutes } from "./PublicRoutes/PublicRoutes";
import {
  After_Verify_Route,
  Dashboard_Routes,
  PrivateRoutes,
  Verify_Route,
} from "./PrivateRoutes/PrivateRoutes";
import ServiceDetailsPage from "../../screens/Dashboard/ServiceDetailPage";
import NotFoundPage from "../../screens/NotFoundPage/NotFoundPage";
import VendorCheckEmail from "../../pages/auth/VendorVerification/VendorCheckEmail/VendorCheckEmail";
import { ALLRoutes } from "./AllRoutes/AllRoutes";
import ButtonComp from "../../component/common/ButtonComp";
import { session_expired } from "../../store/constants";
import { exit_session } from "../apiHandle/apiHandle";
// ALLRoutes

export const RouterApp = () => {
  const {
    login_status,
    login_error,
    userAuth,
    userData,
    check_auth_status,
    userRole,
    vendor_register_status,
    user_register_status,
    user,
  } = useSelector((state) => state.userAuth);

  const isEmailVerified = user?.email_verified_at;

  const dispatch = useDispatch();

  useEffect(() => {
    if (check_auth_status === asyncStatus.IDLE) {
      const authTokens =
        localStorage.getItem(save_tokens_constant.AUTH) || null;
      if (!authTokens) {
        dispatch(setAuthState(false));
      } else {
        dispatch(check_auth({ token: authTokens }));
      }
    }
  }, []);

  if (
    check_auth_status === asyncStatus.IDLE ||
    check_auth_status === asyncStatus.LOADING
  ) {
    return (
      <Stack>
        <LinearProgress />
      </Stack>
    );
  }

  if (check_auth_status === asyncStatus.ERROR) {
    return (
      <Stack
        py={2}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "50vh" }}
        gap={3}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: "red",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          Something went wrong!
        </Typography>
        <ButtonComp
          label="Go To Home"
          onClick={() => exit_session()}
          style={{
            borderRadius: "10px",
            background:
              "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
          }}
        />
      </Stack>
    );
  }
  return (
    <Router>
      <Routes>
        <Route>
          {React.Children.toArray(
            topBarRoutes.map((route, i) => {
              const { linkTo, element } = route;
              return <Route key={i} element={element} path={linkTo} />;
            })
          )}
        </Route>

        <Route path="/service-details/:id" element={<ServiceDetailsPage />} />
        <Route element={<PublicRoutes />}>
          {React.Children.toArray(
            mainRoutes.map((route, i) => {
              const { linkTo, element, authRequired, both } = route;
              return (
                !authRequired && (
                  <Route key={i} element={element} path={linkTo} />
                )
              );
            })
          )}
        </Route>

        <Route element={<PrivateRoutes />}>
          {React.Children.toArray(
            mainRoutes.map((route, i) => {
              const { linkTo, element, authRequired, both } = route;

              return (
                authRequired && (
                  <Route key={i} element={element} path={linkTo} />
                )
              );
            })
          )}
        </Route>

        <Route path="*" ele ment={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};
