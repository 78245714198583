import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import logo from "../../assets/logo.png";
import logo2 from "../../assets/logo_2.png";
import { Container, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import "./navbar.css";
import { useSelector } from "react-redux";
import CustomButton from "../common/Button/Button";
import { themeOrange, themeText } from "../../utils/colorTheme";
import { FaLocationDot } from "react-icons/fa6";
import ProfileMenu from "../common/menu/ProfileMenu";
import { topBarRoutes } from "../../utils/routeList";

const drawerWidth = 240;

function Navbar(props) {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { user, userAuth } = useSelector((state) => state.userAuth);

  const isEmailVerified = user?.email_verified_at;
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleSignUp = () => {
    navigate("/Signup");
  };

  const handleLogin = () => {
    navigate("/Login");
  };
  const handleSignUpUser = () => {
    navigate("/signup-user");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box onClick={() => navigate("/")} sx={{ my: 2, cursor: "pointer" }}>
        <img
          style={{ objectFit: "cover", width: "60%", cursor: "pointer" }}
          src={logo}
        />
      </Box>
      <Divider />
      <List>
        {topBarRoutes
          ?.filter(({ isInTopBar }) => isInTopBar === true)
          ?.map((item, i) => {
            return (
              <ListItem key={i} disablePadding>
                <ListItemButton
                  onClick={() => navigate(item.linkTo)}
                  sx={{
                    textAlign: "center",
                    color: location.pathname === item.linkTo ? "black" : "grey",
                    fontWeight:
                      location.pathname === item.linkTo ? "900" : "400",
                  }}
                >
                  <ListItemText primary={item.caption} />
                </ListItemButton>
              </ListItem>
            );
          })}
        <Stack
          spacing={2}
          style={{ flexWrap: "wrap", justifyContent: "space-between" }}
        >
          {!userAuth && (
            <>
              <Stack justifyContent={"center"} alignItems="center">
                <CustomButton onClick={handleSignUpUser}>Signup</CustomButton>
              </Stack>
              <Stack justifyContent={"center"} alignItems="center">
                <CustomButton onClick={handleLogin}>Login</CustomButton>
              </Stack>
              <Stack justifyContent={"center"} alignItems="center">
                <CustomButton onClick={handleSignUp}>Pro Signup</CustomButton>
              </Stack>
            </>
          )}
        </Stack>
      </List>
    </Box>
  );

  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Container maxWidth="xl">
      <Box sx={{ display: "flex", color: "black" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          color={scrolled ? "inherit" : "transparent"}
          position="fixed"
          sx={{
            pt: 2,
            pb: 1,
            transition: "background-color 0.3s ease-in-out",
          }}
          elevation={scrolled ? 5 : 0}
        >
          <Stack
            className="sethampburger"
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            flexShrink={"0"}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className="hamBurger"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: "none",
                color: themeOrange,
                flexShrink: 0,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              onClick={() => navigate("/")}
              sx={{
                display: {
                  md: "block",
                  sm: "none",
                  xs: "none",
                  cursor: "pointer",
                },
              }}
            >
              <img
                style={{ objectFit: "cover", width: "134px" }}
                src={scrolled ? logo : logo2}
              />
            </Box>
            <Stack
              sx={{
                flexGrow: {
                  xs: 1,
                  sm: 1,
                  md: 0,
                },
              }}
              direction="row"
              alignItems="center"
              gap={2}
            >
              {/* CART */}

              <Stack className="cart">
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    position: "absolute",
                    right: 1,
                    top: 13,
                  }}
                >
                  {!userAuth ? (
                    <Stack>
                      {/* <Stack justifyContent={"center"} alignItems="center">
                        <CustomButton
                          onClick={handleSignUp}
                          style={{ padding: "14px 30px" }}
                        >
                          Signup
                        </CustomButton>
                      </Stack>
                      <Stack justifyContent={"center"} alignItems="center">
                        <CustomButton
                          onClick={handleLogin}
                          style={{
                            backgroundColor: "transparent",
                            color: themeText,
                            border: `1px solid ${themeText}`,
                            padding: "12px 28px",
                          }}
                        >
                          Login
                        </CustomButton>
                      </Stack> */}
                    </Stack>
                  ) : (
                    <Stack>
                      <Stack
                        justifyContent={"flex-end"}
                        alignItems="center"
                        px={2}
                        flexDirection={"row"}
                      >
                        <CustomButton
                          style={{
                            backgroundColor: "#FFE2D8",
                            color: "black",
                            borderRadius: "60px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            padding: "6px 25px",
                          }}
                        >
                          <FaLocationDot
                            color="#f2561d"
                            style={{ margin: "4px 5px" }}
                            size={20}
                          />{" "}
                          Canada
                        </CustomButton>
                        <Stack>
                          <ProfileMenu />
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                }}
              >
                {topBarRoutes
                  ?.filter(({ isInTopBar }) => isInTopBar === true)
                  ?.map((data, i) => {
                    return (
                      <Typography
                        key={i}
                        onClick={() => navigate(data.linkTo)}
                        sx={{
                          "&:hover": { color: themeOrange },
                          cursor: "pointer",
                          fontWeight:
                            location.pathname === data.linkTo ? "600" : "300",
                          color:
                            location.pathname === data.linkTo
                              ? themeOrange
                              : scrolled
                              ? "grey"
                              : "white",
                          ml: 3,
                        }}
                      >
                        {data.caption}
                      </Typography>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack
              sx={{
                display: {
                  xs: "none",
                  sm: "none",

                  md: "flex",
                  color: "black",
                  flexDirection: "row",
                },
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              {!userAuth ? (
                <>
                  <Stack justifyContent={"center"} alignItems="center">
                    <CustomButton
                      onClick={handleSignUpUser}
                      style={{
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                      }}
                    >
                      Sign Up
                    </CustomButton>
                  </Stack>
                  <Stack justifyContent={"center"} alignItems="center">
                    <CustomButton
                      onClick={handleLogin}
                      style={{
                        backgroundColor: "transparent",
                        color: scrolled ? themeText : "white",
                        border: `1px solid ${scrolled ? themeText : "white"}`,
                      }}
                    >
                      Sign In
                    </CustomButton>
                  </Stack>
                  <Stack justifyContent={"center"} alignItems="center">
                    <CustomButton
                      onClick={handleSignUp}
                      style={{
                        backgroundColor: "transparent",
                        color: scrolled ? themeText : "white",
                        border: `1px solid ${scrolled ? themeText : "white"}`,
                      }}
                    >
                      Pro Sign Up
                    </CustomButton>
                  </Stack>
                </>
              ) : (
                <Stack flexDirection={"row"} alignItems={"center"}>
                  {" "}
                  <Stack justifyContent={"center"} alignItems="center" px={2}>
                    <CustomButton
                      style={{
                        backgroundColor: "#FFE2D8",
                        color: themeText,
                        borderRadius: "50px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        padding: "6px 25px",
                      }}
                    >
                      <FaLocationDot
                        color="#f2561d"
                        style={{ margin: "4px 5px" }}
                        size={20}
                      />{" "}
                      Canada
                    </CustomButton>
                  </Stack>
                  <Stack>
                    <ProfileMenu />
                  </Stack>
                </Stack>
              )}
              {/* )} */}
            </Stack>
          </Stack>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </Container>
  );
}
Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default Navbar;
