import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { SERVICE_DETAIL_PAGE_CONSTANT } from "../../../store/constants";

export const PublicRoutes = () => {
  const { userAuth, user } = useSelector((state) => state.userAuth);
  const [fromWherePage, setFromWherePage] = useState({ from: "", id: "" });

  const isEmailVerified = user?.email_verified_at;

  useEffect(() => {
    const serviceDetail = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT.from
    );
    const service_id = localStorage.getItem(
      SERVICE_DETAIL_PAGE_CONSTANT.service_id
    );
    setFromWherePage({ ...fromWherePage, id: service_id, from: serviceDetail });
  }, []);

  // Redirect authenticated and verified users to the dashboard
  if (!userAuth) {
    return <Outlet />;
  } else if (userAuth && !isEmailVerified && +user?.verify_email === 0) {
    return <Navigate to="/vendor-check-email" />;
  } else if (userAuth && +user?.user_role === 2) {
    return <Navigate to="/dashboard/" />;
  } else if (fromWherePage.from === "FROM_SERVICE_DETAIL" && fromWherePage.id) {
    return <Navigate to={`/service-details/${fromWherePage.id}`} />;
  } else {
    return <Navigate to="/" />;
  }
};
