import React, { useEffect, useState } from "react";
import css from "./DoneServices.module.css";
import Navbar from "../../component/navbar/Navbar";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { themeOrange } from "../../utils/colorTheme";
import { ServicesCard } from "../../component/common/Card/ServicesCard/ServicesCard";
import { imgUrl } from "../../utils/imageUrl";
import DoneServiceSearchBar from "../../component/common/icon-searchbar/DoneServiceSearchBar";
import { asyncStatus } from "../../utils/asyncStatus";
import { get_services } from "../../services/services";
import Footer from "../../screens/Footer/Footer";
import { ImSearch } from "react-icons/im";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { setJobsImages } from "../../store/slices/services_slice";
import { service_booking_keys, steps_constants } from "../../store/constants";

const DoneServices = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query") || "";

  const { get_all_services_status, get_all_services_data } = useSelector(
    (state) => state.serviceManage
  );
  const getServiceLoader = get_all_services_status === asyncStatus.LOADING;

  const _handleClick = (item) => {
    navigate(`/service-details/${item}`);
    dispatch(setJobsSteps({ value: null }));
    localStorage.removeItem(steps_constants.JOBS_STEP);
    localStorage.removeItem(service_booking_keys.DATE);
    localStorage.removeItem(service_booking_keys.TIME);
    localStorage.removeItem(service_booking_keys.DESCRIPTION);
    localStorage.removeItem(service_booking_keys.LOCATION);
    localStorage.removeItem(service_booking_keys.PRICE);
    localStorage.removeItem(service_booking_keys.IMAGES);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(get_services());
  }, [dispatch]);

  useEffect(() => {
    setSearchTerm(query);
  }, [query]);

  const filteredServices = get_all_services_data?.services?.filter((service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const _handleVal = (id) => {
    setSearchTerm("");
    navigate(`/service-details/${id}`);
    dispatch(setJobsSteps({ value: null }));
    localStorage.removeItem(steps_constants.JOBS_STEP);
    localStorage.removeItem(service_booking_keys.DATE);
    localStorage.removeItem(service_booking_keys.TIME);
    localStorage.removeItem(service_booking_keys.DESCRIPTION);
    localStorage.removeItem(service_booking_keys.LOCATION);
    localStorage.removeItem(service_booking_keys.PRICE);
    localStorage.removeItem(service_booking_keys.IMAGES);
  };

  return (
    <div>
      <Navbar />

      <div className={css.img_wrapper}>
        <Stack
          sx={{
            backgroundColor: "#362d2db3",
            height: "100%",
            width: "100%",
          }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack
            sx={{
              width: {
                xl: "60%",
                lg: "60%",
                md: "70%",
                sm: "80%",
                xs: "90%",
              },
              mt: 15,
            }}
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <DoneServiceSearchBar
              initialQuery={query}
              onSearch={setSearchTerm}
            />

            {searchTerm ? (
              filteredServices?.length > 0 ? (
                <Grid
                  container
                  alignItems={"stretch"}
                  spacing={2}
                  justifyContent={"center"}
                >
                  {filteredServices?.slice(0, 6)?.map((e, i) => (
                    <Grid key={i} item xl={4} lg={4} md={4} sm={6} xs={11}>
                      <Stack
                        onClick={() => _handleVal(e?.slug)}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          height: "100%",
                          borderRadius: "10px",
                          backgroundColor: "white",
                          overflow: "hidden",
                          cursor: "pointer",
                          transition:
                            "transform 0.4s ease, box-shadow 0.3s ease",
                          ":hover": {
                            transform: "scale(1.04)",
                            boxShadow: "0 8px 16px rgba(255, 255, 255, 0.23)",
                          },
                        }}
                      >
                        <Stack sx={{ height: "100%" }}>
                          <img
                            src={`${imgUrl}${e?.mobile_image || ""}`}
                            style={{
                              width: "100px",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Stack>

                        <Stack
                          justifyContent={"center"}
                          sx={{ height: "100px", flex: 1 }}
                          py={2}
                          px={2}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontWeight: "500",
                              fontSize: {
                                xl: "15px",
                                lg: "15px",
                                md: "14px",
                                sm: "13px",
                                xs: "12px",
                              },
                            }}
                          >
                            {e.name || ""}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                  sx={{ width: "100%" }}
                  mt={10}
                >
                  <ImSearch size={30} style={{ color: themeOrange }} />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "white",
                      fontWeight: "500",
                      fontSize: {
                        xl: "20px",
                        lg: "20px",
                        md: "18px",
                        sm: "16px",
                        xs: "15px",
                      },
                    }}
                  >
                    Hmm. We didn't quite catch that.
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={{
                      width: {
                        xl: "50%",
                        lg: "50%",
                        md: "70%",
                        sm: "80%",
                        xs: "90%",
                      },
                      textAlign: "center",
                      color: "lightgrey",
                      fontWeight: "500",
                      fontSize: {
                        xl: "15px",
                        lg: "15px",
                        md: "14px",
                        sm: "13px",
                        xs: "12px",
                      },
                    }}
                  >
                    Chances are one of our pros will be able to help.{" "}
                    <Typography
                      onClick={() => navigate("/help-center")}
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        color: themeOrange,
                        fontWeight: "500",
                        fontSize: {
                          xl: "15px",
                          lg: "15px",
                          md: "14px",
                          sm: "13px",
                          xs: "12px",
                        },
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Contact us
                    </Typography>{" "}
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        color: "lightgrey",
                        fontWeight: "500",
                        fontSize: {
                          xl: "15px",
                          lg: "15px",
                          md: "14px",
                          sm: "13px",
                          xs: "12px",
                        },
                      }}
                    >
                      and we’ll do our best to find you the right one!
                    </Typography>
                  </Typography>
                </Stack>
              )
            ) : null}
          </Stack>
        </Stack>
      </div>

      <Container maxWidth="xl">
        {getServiceLoader ? (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "100vh", width: "100%" }}
          >
            <CircularProgress size={30} sx={{ color: themeOrange }} />
          </Stack>
        ) : (
          <Stack>
            <Stack sx={{ backgroundColor: "white", py: 10 }}>
              <Typography className={css.second_section_heading}>
                Explore <span style={{ color: "#F15A24" }}>all Services</span>
              </Typography>
              <Grid container spacing={4} justifyContent={"center"}>
                {get_all_services_data?.services?.length > 0 &&
                  get_all_services_data?.services.map((e, i) => (
                    <Grid key={i} item xl={2} lg={2} md={3} sm={6} xs={11}>
                      <ServicesCard
                        src={`${imgUrl}${e?.image}`}
                        heading={e?.name}
                        sub_heading={e?.description}
                        onClick={() => _handleClick(e?.slug)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Stack>
          </Stack>
        )}
      </Container>
      <Box mt={10}>
        <Footer />
      </Box>
    </div>
  );
};

export default DoneServices;
