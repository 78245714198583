import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCalendarDays, FaLocationDot } from "react-icons/fa6";
import { MdDescription } from "react-icons/md";
import { themeBlue, themeGray, themeOrange } from "../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { acceptJobAsync, getJobsAsync } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { imgUrl } from "../../utils/imageUrl";
import {
  error_toast_message,
  success_toast_message,
} from "../../utils/toast_message";
import { inputDateFormate } from "../../utils/CustomFormator";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Import the marker icon and shadow images from Leaflet
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import ButtonComp from "../../component/common/ButtonComp";

import css from "./VendorDashboard.module.css";
import CustomSelectComp from "../../component/common/selectComp/CustomSelectComp";
import { Form, Formik } from "formik";
import { getValidationSchema } from "../../pages/auth/ValidationsFormikYup/ValidationsSchema";
import { format } from "date-fns";
import { setJobAcceptStatus } from "../../store/slices/services_slice";

// Set the default icon for markers
const customMarkerIcon = new L.Icon({
  iconUrl: markerIconPng,
  shadowUrl: markerShadowPng,
  iconSize: [25, 41], // size of the icon
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  shadowSize: [41, 41], // size of the shadow
});

const JobRequest = () => {
  const dispatch = useDispatch();
  const [seeDes, setSeeDes] = useState(false);
  const [detailInd, setDetailInd] = useState();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [positions, setPositions] = useState({});
  const [dates, setDates] = useState({});
  const [selectedValue, setSelectedValue] = useState("Morning");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log("Radio Button: ", event.target.value);
  };

  const { get_jobs_status, get_jobs_data, accept_job_status } = useSelector(
    (state) => state.serviceManage
  );
  const { user } = useSelector((state) => state.userAuth);

  const getJobsLoader = get_jobs_status === asyncStatus.LOADING;
  const jobAcceptLoading = accept_job_status === asyncStatus.LOADING;

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  useEffect(() => {
    const fetchCoordinates = async (location, jobId) => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apiKey}`
        );
        const result = response?.data?.results[0];
        const { lat, lng } = result?.geometry?.location || {};

        if (lat && lng) {
          setPositions((prevPositions) => ({
            ...prevPositions,
            [jobId]: [lat, lng],
          }));
        } else {
          console.warn(`Geocoding failed for location: ${location}`);
        }
      } catch (error) {
        console.error("Geocoding error:", error);
      }
    };

    if (get_jobs_data?.length) {
      get_jobs_data?.forEach((job) => {
        if (job?.location) {
          fetchCoordinates(job?.location, job?.id);
        }
      });
    }
  }, [get_jobs_data, apiKey]);

  const userStatus = user?.status === 1;

  const _handleMapClick = (position) => {
    if (userStatus && position) {
      const googleMapsUrl = `https://www.google.com/maps?q=${position[0]},${position[1]}`;
      window.open(googleMapsUrl, "_blank");
    }
  };

  const _getMainLocation = (location) => {
    return location ? location.split(",")[0] : "";
  };

  useEffect(() => {
    dispatch(getJobsAsync());
  }, []);

  const _handleViewDetail = (id) => {
    setDetailInd(id);
  };

  const _handleHideDetail = (id) => {
    setDetailInd();
  };

  const _handleLocationLength = (desc) => {
    const des = desc.slice(0, 34);
    return des.length > 35 ? (seeDes ? des : `${des}...`) : des;
  };

  const _handleAccept = (values) => {
    setSelectedJobId(values.id);
    dispatch(
      acceptJobAsync({
        order_id: values?.id,
        date_time_lock: { date: values?.date, shift: values?.shift },
      })
    );
  };

  useEffect(() => {
    if (accept_job_status === asyncStatus.ERROR) {
      error_toast_message("Job already accepted by another vendor");
    } else if (accept_job_status === asyncStatus.SUCCEEDED) {
      success_toast_message("Job accept successfully");
    }

    // Reload the window after showing the toast message
    if (
      accept_job_status === asyncStatus.ERROR ||
      accept_job_status === asyncStatus.SUCCEEDED
    ) {
      dispatch(setJobAcceptStatus());
    }
  }, [accept_job_status]);

  const sortedJobs = [...(get_jobs_data || [])]?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  const initialValues = {
    date: "",
    shift: "",
  };

  return (
    <Stack bgcolor={"#FAFAFA"}>
      <Container
        sx={{ maxWidth: { xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Stack gap={3}>
          <Stack>
            <Typography variant="caption" className={css.total}>
              Job{" "}
              <Typography variant="caption" className={css.main_heading}>
                Requests
              </Typography>
            </Typography>
            <hr color={"#D1D1D1"} />
          </Stack>
          {getJobsLoader ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "60vh", width: "100%" }}
            >
              <CircularProgress size={30} sx={{ color: themeOrange }} />
            </Stack>
          ) : sortedJobs?.length > 0 ? (
            sortedJobs?.map(
              ({
                date,
                description,
                id,
                job_end_request,
                location,
                payer_id,
                service_id,
                status,
                time,
                user_id,
                created_at,
                vendor_id,
                user,
                service,
                images,
              }) => {
                const position = positions[id];

                const displayLocation = userStatus
                  ? location
                  : _getMainLocation(location);
                const markerPosition = userStatus ? position : [43.7, -79.42]; // Ontario center
                const availableDates = date?.map(({ selected_date }) => ({
                  value: new Date(selected_date),
                  label: format(selected_date, "EEE, MMM d, yyyy"),
                }));

                return (
                  <Grid container spacing={2} alignItems={"stretch"}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={detailInd === id && 8}
                      lg={detailInd === id && 8}
                      xl={detailInd === id && 8}
                    >
                      <Stack
                        mt={2}
                        p={2}
                        sx={{
                          height: "100%",
                          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
                          borderRadius: 4,
                          backgroundColor: "white",
                        }}
                      >
                        <Stack
                          flexDirection={{
                            xl: "row",
                            lg: "row",
                            md: "row",
                            sm: "row",
                            xs: "column",
                          }}
                          alignItems={"center"}
                          gap={2}
                          my={1}
                          px={2}
                        >
                          <img
                            width={"99px"}
                            style={{
                              width: "99px",
                              height: "99px",
                              objectFit: "contain",
                              borderRadius: "10px",
                              backgroundColor: "lightgrey",
                            }}
                            src={`${imgUrl}${user?.avatar}`}
                          />
                          <Stack
                            gap={1}
                            justifyContent={"center"}
                            alignItems={{
                              xl: "flex-start",
                              lg: "flex-start",
                              md: "flex-start",
                              sm: "flex-start",
                              xs: "center",
                            }}
                          >
                            <Typography
                              className="subHeading"
                              sx={{ fontFamily: "Raleway !important" }}
                            >
                              {`${user?.first_name || ""} ${
                                user?.last_name || ""
                              }`}
                            </Typography>
                            <Typography
                              className="subPara"
                              sx={{ fontFamily: "Raleway !important" }}
                            >
                              {user?.email || ""}
                            </Typography>
                            {user?.phone && (
                              <Typography
                                className="subPara"
                                sx={{ fontFamily: "Raleway !important" }}
                              >
                                {user?.phone || ""}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                        <Stack px={2} my={1}>
                          <Divider />
                        </Stack>

                        <Stack gap={4} p={2}>
                          <Typography
                            className="mainHeading"
                            sx={{ fontFamily: "Raleway !important" }}
                          >
                            {service?.name ?? ""}
                          </Typography>
                          <Grid container spacing={2}>
                            {/* <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                              {" "}
                              <Stack
                                direction={"row"}
                                justifyContent={"flex-start"}
                                gap={2}
                                alignItems={"center"}
                              >
                                <FaCalendarDays
                                  size={25}
                                  style={{ color: "#071D5B", flexShrink: 0 }}
                                />
                                <Typography
                                  className="subPara"
                                  sx={{ flexShrink: 0 }}
                                >
                                  {inputDateFormate(created_at || "") || ""}
                                </Typography>
                              </Stack>
                            </Grid> */}

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <Stack
                                direction={"row"}
                                justifyContent={"flex-start"}
                                gap={2}
                                alignItems={"flex-start"}
                              >
                                <FaLocationDot
                                  size={25}
                                  style={{ color: "#071D5B", flexShrink: 0 }}
                                />
                                <Typography
                                  className="subPara"
                                  sx={{
                                    flexShrink: "inherit",
                                  }}
                                >
                                  {_handleLocationLength(displayLocation) ?? ""}
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <Stack
                                direction={"row"}
                                justifyContent={"flex-start"}
                                gap={2}
                                alignItems={"flex-start"}
                                sx={{ flex: 1 }}
                              >
                                <MdDescription
                                  size={25}
                                  style={{ color: "#071D5B", flexShrink: 0 }}
                                />

                                <Typography
                                  className="subPara"
                                  // variant="caption"
                                  // onClick={() => setSeeDes(!seeDes)}
                                >
                                  {_handleLocationLength(description) ?? ""}

                                  {/* <Typography
                                    variant="caption"
                                    sx={{
                                      color: themeOrange,
                                      ml: "1px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    {!seeDes ? "see more" : "see less"}
                                  </Typography> */}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>

                          <Stack
                            direction={"row"}
                            width={1}
                            // px={6}
                            // alignItems={"center"}
                            // justifyContent={"center"}
                          >
                            <Stack
                              p={2}
                              sx={{
                                border: "0.5px solid #F1F1F1",
                                borderRadius: "15px",
                                backgroundColor: "#FFF",
                              }}
                            >
                              <Typography
                                className="mainPara"
                                sx={{ fontFamily: "Raleway !important" }}
                              >
                                {description ?? ""}
                              </Typography>
                            </Stack>
                          </Stack>

                          <Formik
                            initialValues={initialValues}
                            validationSchema={getValidationSchema(
                              "vendor_date_shift_selection"
                            )}
                            onSubmit={(values) => {
                              // Combine the id and form values
                              const dataToSubmit = {
                                id: id, // You can access the `id` from the component's props or state
                                date: values.date,
                                shift: values.shift,
                              };
                              _handleAccept(dataToSubmit); // Call your submission handler with the combined data
                            }}
                          >
                            {({ errors, touched, setFieldValue, values }) => {
                              const availableShifts = date?.filter(
                                ({ selected_date }) =>
                                  new Date(selected_date).toString() ===
                                  new Date(values.date).toString()
                              )[0];
                              return (
                                <Form>
                                  <Stack gap={3}>
                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Grid container columnSpacing={7}>
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          my={1}
                                        >
                                          <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "500" }}
                                            // sx={{
                                            //   color: "red",
                                            //   fontSize: "12px",
                                            // }}
                                          >
                                            Choose Your Availability
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xl={4}
                                          lg={4}
                                          md={4}
                                          sm={12}
                                          xs={12}
                                        >
                                          <CustomSelectComp
                                            options={availableDates || []}
                                            // value={values.date}
                                            onChange={(e) =>
                                              setFieldValue("date", e.value)
                                            }
                                            isMulti={false}
                                            defaultstyle
                                            customStyles={{
                                              control: {
                                                borderColor: "blue",
                                                height: "45px",
                                                borderColor: themeOrange,
                                                borderRadius: "6px",
                                              },
                                              multiValueLabel: {
                                                fontSize: "14px",
                                              },
                                            }}
                                            customColor={themeOrange}
                                            error={
                                              errors.date && touched.date
                                                ? errors.date
                                                : ""
                                            }
                                          />
                                        </Grid>
                                        {values.date && (
                                          <Grid
                                            item
                                            xl={8}
                                            lg={8}
                                            md={8}
                                            sm={12}
                                            xs={12}
                                          >
                                            <Stack
                                              direction={"row"}
                                              alignItems={"center"}
                                              justifyContent={"space-between"}
                                              py={0.7}
                                            >
                                              <FormControl
                                                sx={{
                                                  width: "100%",
                                                }}
                                              >
                                                <RadioGroup
                                                  value={values.shift}
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      "shift",
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <Stack
                                                    direction="row"
                                                    alignItems={"center"}
                                                    // justifyContent={
                                                    //   "space-between"
                                                    // }
                                                    gap={5}
                                                    sx={{
                                                      width: "100%",
                                                    }}
                                                  >
                                                    {availableShifts?.shifts?.map(
                                                      (shift) => (
                                                        <FormControlLabel
                                                          key={shift}
                                                          value={shift}
                                                          control={
                                                            <Radio
                                                              sx={{
                                                                color:
                                                                  themeOrange,
                                                                "&.Mui-checked":
                                                                  {
                                                                    color:
                                                                      themeOrange,
                                                                  },
                                                              }}
                                                            />
                                                          }
                                                          label={shift}
                                                        />
                                                      )
                                                    )}
                                                  </Stack>
                                                </RadioGroup>
                                              </FormControl>
                                            </Stack>
                                            {errors.shift && touched.shift && (
                                              <Typography
                                                sx={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {errors.shift}
                                              </Typography>
                                            )}
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Stack>

                                    <Stack
                                      direction={"row"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                      // px={5}
                                    >
                                      <Stack>
                                        {detailInd === id ? (
                                          <ButtonComp
                                            onClick={_handleHideDetail}
                                            style={{
                                              padding: "10px 30px",
                                              fontfamily: "Raleway !important",
                                              background:
                                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",

                                              borderRadius: "10px",
                                            }}
                                            label={"Hide Details"}
                                          />
                                        ) : (
                                          <ButtonComp
                                            onClick={() =>
                                              _handleViewDetail(id)
                                            }
                                            style={{
                                              padding: "10px 30px",
                                              padding: "10px 30px",
                                              fontfamily: "Raleway !important",
                                              background:
                                                "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",

                                              borderRadius: "10px",
                                            }}
                                            label={"View Details"}
                                          />
                                        )}
                                      </Stack>
                                      {
                                        <Stack
                                          direction={"row"}
                                          alignItems={"center"}
                                          gap={2}
                                        >
                                          {userStatus && (
                                            <Button
                                              type="submit"
                                              variant="contained"
                                              sx={{
                                                backgroundColor: "#52C24A",
                                                height: "45px",
                                                width: "auto",
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                ":hover": {
                                                  backgroundColor: "#52C24A",
                                                },
                                                borderRadius: "10px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "10px 30px",
                                                textTransform: "capitalize",
                                              }}
                                              // onClick={() => _handleAccept(id)}
                                            >
                                              {jobAcceptLoading &&
                                              selectedJobId === id ? (
                                                <CircularProgress
                                                  size={15}
                                                  sx={{ color: "white" }}
                                                />
                                              ) : (
                                                "Accept"
                                              )}
                                            </Button>
                                          )}
                                        </Stack>
                                      }
                                    </Stack>
                                  </Stack>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Stack>
                      </Stack>
                    </Grid>
                    {detailInd === id && (
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Stack
                          mt={2}
                          sx={{
                            borderRadius: "16px",
                            backgroundColor: "white",
                            height: "488px",
                            overflow: "auto",
                          }}
                        >
                          <Stack
                            className="globleGradientBlue"
                            p={2}
                            borderRadius={"10px 10px 0px 0px"}
                          >
                            <Typography className="subHeading" color={"white"}>
                              {service?.name || ""}
                            </Typography>
                          </Stack>

                          <Stack mt={4} gap={3} p={2}>
                            <Stack gap={1}>
                              {date?.map(({ selected_date, shifts }) => {
                                return (
                                  <Stack
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                    gap={2}
                                  >
                                    <Stack>
                                      {/* <FaCalendarDays color={themeOrange} size={26} /> */}
                                      <FaCalendarDays
                                        size={20}
                                        style={{
                                          color: themeBlue,
                                          flexShrink: 0,
                                        }}
                                      />
                                    </Stack>
                                    <Typography
                                      className="subpara"
                                      sx={{
                                        color: "#333333",
                                        fontSize: "15px !important",
                                      }}
                                    >
                                      {format(
                                        selected_date,
                                        "EEE, MMM d, yyyy"
                                      )}
                                    </Typography>
                                    <Typography className="service_shifts_txt">
                                      (
                                      {shifts
                                        ?.map((shift) => shift?.slice(0, 3))
                                        .join(", ")}
                                      )
                                    </Typography>
                                  </Stack>
                                );
                              })}
                            </Stack>
                            {/* <Stack
                              direction={"row"}
                              justifyContent={"start"}
                              gap={2}
                              alignItems={"center"}
                            >
                              <FaCalendarDays
                                size={25}
                                style={{ color: themeBlue, flexShrink: 0 }}
                              />
                              <Typography className="subPara">
                                {inputDateFormate(date) || ""}
                              </Typography>
                            </Stack> */}

                            <Stack
                              direction={"row"}
                              justifyContent={"start"}
                              gap={2}
                            >
                              <MdDescription
                                size={25}
                                style={{ color: themeBlue, flexShrink: 0 }}
                              />
                              <Typography className="subPara">
                                {description ?? ""}
                              </Typography>
                            </Stack>
                            {displayLocation && (
                              <Stack
                                direction={"row"}
                                justifyContent={"start"}
                                gap={2}
                                alignItems={"center"}
                              >
                                <FaLocationDot
                                  size={25}
                                  style={{ color: themeBlue, flexShrink: 0 }}
                                />
                                <Typography className="subPara">
                                  {displayLocation || ""}
                                </Typography>
                              </Stack>
                            )}
                            <div
                              style={{
                                height: "162px",
                                width: "100%",
                                borderRadius: "20px",
                                overflow: "hidden",
                              }}
                            >
                              {position ? (
                                <MapContainer
                                  center={position}
                                  zoom={13}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                  />
                                  <Marker
                                    icon={customMarkerIcon}
                                    // eventHandlers={{
                                    //   click: () => _handleMapClick(position),
                                    // }}
                                    eventHandlers={
                                      userStatus
                                        ? {
                                            click: () =>
                                              _handleMapClick(markerPosition),
                                          }
                                        : {}
                                    }
                                    position={position}
                                  >
                                    {userStatus && <Popup>{location}</Popup>}
                                  </Marker>
                                </MapContainer>
                              ) : (
                                <Stack
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  sx={{
                                    height: "100%",
                                  }}
                                >
                                  <CircularProgress
                                    size={15}
                                    sx={{ color: themeOrange }}
                                  />
                                </Stack>
                              )}
                            </div>
                            <Stack gap={1}>
                              <Typography className="secondSubHeading">
                                Job Description
                              </Typography>
                              <Typography
                                className="mainPara"
                                color={"grey"}
                                sx={{
                                  border: "0.6px solid #F1F1F1",
                                  padding: "10px",
                                  borderRadius: "20px",
                                }}
                              >
                                {description || ""}
                              </Typography>
                            </Stack>

                            <Stack gap={1}>
                              <Typography className="secondSubHeading">
                                Timing constraints
                              </Typography>
                              <Typography
                                className="mainPara"
                                color={"grey"}
                                sx={{
                                  border: "0.6px solid #F1F1F1",
                                  padding: "10px",
                                  borderRadius: "20px",
                                }}
                              >
                                {time || ""}
                              </Typography>
                            </Stack>

                            <Stack sx={{ width: "100%" }}>
                              <Stack
                                gap={2}
                                // sx={{ overflow: "auto", width: "100%" }}
                              >
                                {images?.map(({ url, caption }) => {
                                  return (
                                    <Grid
                                      key={url}
                                      container
                                      // spacing={1}
                                      sx={{
                                        border: "1px solid #F1F1F1",
                                        borderRadius: "20px",
                                        padding: "10px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        xs={4}
                                      >
                                        <img
                                          src={url}
                                          style={{
                                            width: "100%",
                                            objectFit: "cover",
                                            height: "80px",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xl={8}
                                        lg={8}
                                        md={8}
                                        sm={8}
                                        xs={8}
                                      >
                                        <Typography
                                          className="mainPara"
                                          sx={{
                                            // border: "0.6px solid #F1F1F1",
                                            // padding: "10px",
                                            // borderRadius: "20px",
                                            color: "gray",
                                          }}
                                        >
                                          {caption || ""}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                );
              }
            )
          ) : (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "30vh", width: "100%" }}
            >
              <Typography className="secondSubHeading">
                No Jobs found
              </Typography>
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default JobRequest;
