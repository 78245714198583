export const common_caption_strings = {
    first_name: "First Name",
    last_name: "Last Name",
    phone: "Phone Number",
    example_email: "abc@mail.com",
    password: "Password",
    confirm_Password: "Confirm Password",
    here_about_us: "How did you hear about us? optional",
    already_have_account: "I already have an account?",
    Login: "Sign In",
    Signup: "Sign Up",
    Create_one: "Create one",
    resend: "Resend",
    dont_have_account: "Need an account?",
    Or: "Or",
    continue_with_google: "Continue with Google account",
    continue_with_facebook: "Continue with Facebook account",
    continue_with_apple: "Continue with Apple id",
    submit: "Submit",
    confirm: "Confirm",
    confirm_Password: "Confirm Password",
}