import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Stack,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { themeGray, themeOrange } from "../../utils/colorTheme";
import profile from "../../assets/profile.png";
import { BiExport } from "react-icons/bi";
import CustomModal from "../../component/common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import Input from "../../component/common/Input";
import { MdLibraryAdd, MdOutlineEdit } from "react-icons/md";
import exportImg from "../../assets/export.png";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { edit_profile, get_user_profile } from "../../services/authentication";
import { asyncStatus } from "../../utils/asyncStatus";
import { imgUrl } from "../../utils/imageUrl";
import { setUserProfileUpdateStatus } from "../../store/slices/user_auth_slice";
import CustomPhoneInput from "../../component/common/CustomPhoneInput/CustomPhoneInput";

const SettingsUser = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState({});
  const [showPass, setShowPass] = useState({
    password: true,
    confirmPassword: true,
  });
  const [inputErrors, setInputErrors] = useState({
    first_name_err: "",
    last_name_err: "",
  });
  const {
    get_user_profile_status,
    get_user_profile_data,
    edit_profile_status,
  } = useSelector((state) => state.userAuth);

  const dispatch = useDispatch();

  const validateName = (name, field) => {
    const fieldName =
      field.split("_").join(" ").charAt(0).toUpperCase() +
      field.split("_")[0].slice(1) +
      " " +
      field.split("_")[1].charAt(0).toUpperCase() +
      field.split("_")[1].slice(1);
    console.log("hfhfjfh", fieldName);
    let error = "";
    // Check if the name is empty
    if (!name.trim()) {
      error = `${fieldName} cannot be empty.`;
    } else if (/\d/.test(name.charAt(0))) {
      // Check if the first character is a number
      error = `${fieldName} cannot start with a number.`;
    } else if (/^[a-z]/.test(name.charAt(0))) {
      // Check if the first character is not a lowercase letter
      error = `${fieldName} must start with a capital letter.`;
    }
    return error;
  };

  const ratingChanged = (newRating) => {
    // console.log(newRating);
  };

  const _handleFieldsChange = (key, value) => {
    let error = "";
    if (key === "first_name" || key === "last_name") {
      error = validateName(value, key);
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        [`${key}_err`]: error,
      }));
    }
    if (!error) {
      setData({ ...data, [key]: value });
    }
  };

  useEffect(() => {
    dispatch(get_user_profile());
  }, []);

  const profileDataLoader = get_user_profile_status === asyncStatus.LOADING;
  const profileUpdateLoader = edit_profile_status === asyncStatus.LOADING;

  const userProfileData = [
    {
      id: "first_name",
      label: "First Name",
      value: data.first_name,
      disable: !isDisable,
      type: "text",
      placeholder: "Name",
      defaultVal:
        get_user_profile_data?.first_name?.charAt(0)?.toUpperCase() +
        get_user_profile_data?.first_name?.slice(1),
      onchange: (e) => _handleFieldsChange(e.target.id, e.target.value),
      error: inputErrors.first_name_err,
    },
    {
      id: "last_name",
      label: "Last Name",
      value: data.last_name,
      disable: !isDisable,
      type: "text",
      placeholder: "Last Name",
      defaultVal:
        get_user_profile_data?.last_name?.charAt(0)?.toUpperCase() +
        get_user_profile_data?.last_name?.slice(1),
      onchange: (e) => _handleFieldsChange(e.target.id, e.target.value),
      error: inputErrors.last_name_err,
    },
    {
      id: "email",
      label: "Email ID",
      value: data.email,
      disable: !isDisable,
      type: "email",
      placeholder: "Email ID",
      defaultVal: get_user_profile_data?.email,
      onchange: (e) => _handleFieldsChange(e.target.id, e.target.value),
    },
    {
      id: "phone",
      label: "Phone Number",
      value: data.phone,
      disable: !isDisable,
      type: "text",
      placeholder: "Phone Number",
      defaultVal: get_user_profile_data?.phone,
      onchange: (e) => _handleFieldsChange("phone", e),
    },
    // {
    //   id: "",
    //   label: "Password",
    //   value: "",
    //   disable: isDisable,
    //   type: showPass.password ? "password" : "text",
    //   placeholder: "Password",
    //   defaultVal: "jonas12345",
    //   onchange: (e) => _handleFieldsChange(e.target.id,e.target.value),
    //   key: "password",
    // },
    // {
    //   id: "",
    //   label: "Confirm Password",
    //   value: "",
    //   disable: isDisable,
    //   type: showPass.confirmPassword ? "password" : "text",
    //   placeholder: "Password",
    //   defaultVal: "jonas12345",
    //   onchange: (e) => _handleFieldsChange(e.target.id,e.target.value),
    //   key: "confirmPassword",
    // },
  ];

  const _toggleShowPassword = (field) => {
    setShowPass({
      ...showPass,
      [field]: !showPass[field],
    });
  };

  const _handleModal = () => {
    setIsOpenModal(false);
  };

  const _handleUploadClick = () => {
    document.getElementById("upload-input").click();
  };

  const _handleFileChange = (e) => {
    const file = e.target.files[0]; // Single file
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleEdit = () => {
    dispatch(edit_profile(data));
  };

  useEffect(() => {
    if (edit_profile_status === asyncStatus.SUCCEEDED) {
      setIsDisable(false);
      setIsOpenModal(false);
      setIsDisable(!isDisable);
      dispatch(setUserProfileUpdateStatus());
    }
  }, [edit_profile_status]);

  const _handleUploadImage = () => {
    setData({ ...data, image: selectedImage });
    setIsOpenModal(!isOpenModal);
    setSelectedImage(null);
  };

  const displayedImage = data.image
    ? URL.createObjectURL(data.image)
    : `${imgUrl}${get_user_profile_data?.avatar || profile}`;

  const isButtonDisabled =
    !!inputErrors.first_name_err || !!inputErrors.last_name_err;

  return (
    <Stack bgcolor={themeGray}>
      <Container
        maxWidth={{ xl: "xl", lg: "lg", md: "md", sm: "sm", xs: "xs" }}
      >
        <Stack gap={1} pb={2}>
          <Stack>
            <Typography className="mainHeading">My Profile</Typography>
            <hr color={"#D1D1D1"} />
          </Stack>
        </Stack>

        {profileDataLoader ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <CircularProgress size={30} sx={{ color: themeOrange }} />
          </Stack>
        ) : !get_user_profile_data && !get_user_profile_data?.id ? (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "60vh", width: "100%" }}
          >
            <Typography>No Profile Found</Typography>
          </Stack>
        ) : (
          <Container maxWidth="lg">
            <Grid container spacing={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Stack
                  sx={{
                    border: "1px solid #D1D1D1",
                    borderRadius: "10px",
                    mx: "15px",
                  }}
                  justifyContent={"space-between"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                    <Stack
                      sx={{
                        boxShadow: "0px 0px 7px 0px grey",
                        borderRadius: "10px",
                        position: "relative",
                      }}
                    >
                      <img
                        width={"120px"}
                        src={displayedImage}
                        style={{ objectFit: "contain", borderRadius: "10px" }}
                      />
                      {isDisable && (
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            position: "absolute",
                            top: "-2px",
                            right: "-10px",
                            borderRadius: "100%",
                            height: "36px",
                            width: "36px",
                            zIndex: "10",
                            backgroundColor: "#353535",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsOpenModal(true)}
                        >
                          <BiExport
                            color="#EC9430"
                            style={{ fontSize: "16px" }}
                          />
                        </Stack>
                      )}

                      <CustomModal
                        isOpen={isOpenModal}
                        setIsOpen={_handleModal}
                      >
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            position: "relative",
                            backgroundColor: "#CB5C0A",
                            px: 10,
                            py: 5,
                            borderRadius: "30px",
                          }}
                          gap={3}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: "15px",
                              right: "15px",
                            }}
                            onClick={() => {
                              setIsOpenModal(false);
                              setSelectedImage(null);
                            }}
                          >
                            <RxCross2
                              style={{ fontSize: "20px", color: "white" }}
                            />
                          </IconButton>

                          {selectedImage && (
                            <Box
                              sx={{
                                height: "180px",
                                width: "180px",
                                objectFit: "contain",
                              }}
                            >
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={URL.createObjectURL(selectedImage)}
                                style={{ objectFit: "contain" }}
                              />
                            </Box>
                          )}
                          <Stack
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{
                              height: "140px",
                              border: "0.5px solid white",
                              borderRadius: "10px",
                              p: 4.5,
                              cursor: "pointer",
                            }}
                            gap={2}
                            onClick={_handleUploadClick}
                          >
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              sx={{
                                height: "100%",
                                borderRadius: "10px",
                                p: 1.5,

                                position: "relative",
                                width: "100%",
                              }}
                              gap={2}
                            >
                              <input
                                id="upload-input"
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={_handleFileChange}
                                style={{ display: "none" }}
                              />
                              <MdLibraryAdd size={48} color={themeOrange} />
                              <Stack className="add-photo-title">
                                <img
                                  width={"40px"}
                                  height={"40px"}
                                  src={exportImg}
                                  style={{ objectFit: "contain" }}
                                />
                              </Stack>
                            </Stack>

                            <Typography
                              sx={{
                                color: "white",
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              Upload Image
                            </Typography>
                          </Stack>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform:"capitalize",
                              backgroundColor: "white",
                              height: "45px",
                              width: "auto",
                              fontSize: "15px",
                              fontWeight: "600",
                              ":hover": {
                                backgroundColor: "white",
                              },
                              borderRadius: "10px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              mx: 2,
                              color: themeOrange,
                            }}
                            onClick={_handleUploadImage}
                          >
                            Upload Image
                          </Button>
                        </Stack>
                      </CustomModal>
                    </Stack>
                    <Stack>
                      <Typography fontWeight={600} className="subHeading">
                        {`${get_user_profile_data?.first_name || ""} ${
                          get_user_profile_data?.last_name || ""
                        }`}
                      </Typography>
                      <Stack flexDirection={"row"} alignItems={"center"}>
                        {/* <Stack>
                          <Typography className="subPara">4.27 / 5</Typography>
                        </Stack> */}
                        {/* <Stack mt={-1}>
                          <ReactStars
                            count={5}
                            value={4}
                            onChange={ratingChanged}
                            size={24}
                            activeColor="#ffd700"
                          />
                        </Stack> */}
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack p={2}>
                    <Button
                      variant="contained"
                      startIcon={isDisable ? "" : <MdOutlineEdit />}
                      sx={{
                        textTransform:"capitalize",
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                        height: "45px",
                        width: "98px",
                        fontSize: "15px",
                        fontWeight: "700",
                        ":hover": {
                          background:
                            "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                        },
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setIsDisable(!isDisable)}
                    >
                      {isDisable ? "Cancel" : "Edit"}
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              {userProfileData.map(
                ({
                  id,
                  label,
                  value,
                  disable,
                  type,
                  placeholder,
                  defaultVal,
                  onchange,
                  key,
                  error,
                }) => {
                  const isPassword = label === "Password";
                  const isConfirmPassword = label === "Confirm Password";
                  const isPhoneNumber = id === "phone";

                  return isPassword || isConfirmPassword ? (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Stack p={2}>
                        <Stack position={"relative"}>
                          <Input
                            id={id}
                            type={type}
                            onChange={onchange}
                            style={{ borderRadius: "10px" }}
                            placeholder={placeholder}
                            label={label}
                            disabled={disable}
                            defaultValue={defaultVal}
                            // value={password}
                          />

                          {showPass[key] ? (
                            <BsFillEyeFill
                              onClick={() =>
                                disable ? "" : _toggleShowPassword(key)
                              }
                              style={{
                                position: "absolute",
                                top: 20,
                                right: 10,
                                cursor: "pointer",
                                color: disable ? "#D1D1D1" : "black",
                              }}
                              size={18}
                            />
                          ) : (
                            <BsFillEyeSlashFill
                              onClick={() =>
                                disable ? "" : _toggleShowPassword(key)
                              }
                              style={{
                                position: "absolute",
                                top: 20,
                                right: 10,
                                cursor: "pointer",
                                color: disable ? "#D1D1D1" : "black",
                              }}
                              size={18}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  ) : isPhoneNumber ? (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Stack p={2}>
                        {disable ? (
                          <Input
                            id={id}
                            type={type}
                            onChange={onchange}
                            sx={{ backgroundColor: "grey" }}
                            placeholder={placeholder}
                            label={label}
                            disabled={disable}
                            defaultValue={defaultVal}
                          />
                        ) : (
                          <CustomPhoneInput
                            value={value}
                            onChange={onchange}
                            error={error}
                            defaultValue={defaultVal}
                            disabled={disable}
                          />
                        )}
                      </Stack>
                    </Grid>
                  ) : (
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Stack p={2}>
                        <Input
                          id={id}
                          type={type}
                          onChange={onchange}
                          sx={{ backgroundColor: "grey" }}
                          placeholder={placeholder}
                          label={label}
                          disabled={disable}
                          defaultValue={defaultVal}
                        />
                        {error && (
                          <Typography sx={{ color: "red", fontSize: "12px" }}>
                            {error}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                  );
                }
              )}

              {isDisable && (
                <Button
                  disabled={isButtonDisabled}
                  variant="contained"
                  sx={{
                    textTransform:"capitalize",
                    background:
                      "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                    height: "45px",
                    width: "100%",
                    fontSize: "15px",
                    fontWeight: "700",
                    ":hover": {
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                    },
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    mx: 2,
                  }}
                  onClick={handleEdit}
                >
                  {!profileUpdateLoader ? (
                    "Update"
                  ) : (
                    <CircularProgress size={15} sx={{ color: "white" }} />
                  )}
                </Button>
              )}
            </Grid>
          </Container>
        )}
      </Container>
    </Stack>
  );
};

export default SettingsUser;
