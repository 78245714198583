import axios from "axios";

/**
 * Uploads an image to the given endpoint and provides a loading state.
 * @param {File} file - The image file to upload.
 * @param {string} endpoint - The API endpoint URL for uploading the image.
 * @param {function} setLoading - Callback function to set the loading state.
 * @param {string} fileKey - The key to use for the file in the form data.
 * @param {string} [token] - Optional authorization token for the request.
 * @returns {Promise<string>} - A promise that resolves to the uploaded file's URL.
 * @throws {Error} - Throws an error if the upload fails.
 */
const uploadImage = async (file, endpoint, setLoading, fileKey = "binary_file", token) => {
  if (!file || !(file instanceof File)) {
    throw new Error("Invalid file provided.");
  }

  const formData = new FormData();
  formData.append(fileKey, file);

  setLoading(true); // Start loading

  try {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios.post(endpoint, formData, { headers });

    setLoading(false); // Stop loading

    if (response.data?.url) {
      return response.data.url; // Assuming the API returns the URL in `url`.
    } else {
      throw new Error("No URL returned from the server.");
    }
  } catch (error) {
    setLoading(false); // Stop loading on error
    console.error("Image upload failed:", error);
    throw error;
  }
};

export default uploadImage;
