import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { themeOrange } from "../../../utils/colorTheme";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidCalendar } from "react-icons/bi";
import { inputDateFormate } from "../../../utils/CustomFormator";
import {
  MdLocationOn,
  MdOutlineLocationOn,
  MdOutlineWatchLater,
  MdWatchLater,
} from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import Input from "../../common/Input";
import { asyncStatus } from "../../../utils/asyncStatus";
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import {
  PostReviewAsync,
  updateJobStatusAsync,
} from "../../../services/services";
import { imgUrl } from "../../../utils/imageUrl";
import { LuCalendarClock } from "react-icons/lu";
import { format } from "date-fns";

const ActiveJobsTab = ({ jobLoader }) => {
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobReview, setjobReview] = useState({});
  const [selectedJobStatus, setSelectedJobStatus] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_all_jobs_data, post_review_status, job_status_update_status } =
    useSelector((state) => state.serviceManage);

  const jobStatusLoader = job_status_update_status === asyncStatus.LOADING;

  const activeJobs = get_all_jobs_data?.filter(
    (job) => job?.status === "Assigned"
  );

  const handleViewDetails = (jobId) => {
    setSelectedJobId((prevId) => (prevId === jobId ? null : jobId));
  };

  const _handleVanderChat = (_id, first_name, last_name, avatar) => {
    navigate(`/dashboard/message-user`, {
      state: {
        id: _id,
        first_name: first_name,
        last_name: last_name,
        avatar: avatar,
      },
    });
  };

  const handleCancel = (elm, status) => {
    let obj = {
      status: status,
      id: elm,
    };
    setSelectedJobStatus(status);
    dispatch(updateJobStatusAsync(obj));
  };

  const handlePostReview = (elm) => {
    let obj = {
      msg: jobReview.msg,
      rating: jobReview.rating,
      oid: elm,
    };
    dispatch(PostReviewAsync(obj));

    setjobReview({
      msg: "",
      rating: "",
    });
  };

  //   if (jobLoader) {
  //     return (
  //       <Stack
  //         alignItems="center"
  //         justifyContent="center"
  //         sx={{ height: "60vh" }}
  //       >
  //         <CircularProgress size={30} sx={{ color: themeOrange }} />
  //       </Stack>
  //     );
  //   }

  if (!activeJobs?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "60vh" }}
      >
        <Typography>No Active Jobs Found</Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      {activeJobs?.map((e, i) => {
        const isSelected = selectedJobId === e?.id;

        return (
          <Stack
            key={e?.id}
            sx={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: "15px",
            }}
          >
            <Stack
              className="globleGradientBlue"
              sx={{
                borderRadius: "15px",
                py: 1.5,
                px: 2,
                boxShadow: "none",
              }}
            >
              <Typography className="secondSubHeading" sx={{ color: "white" }}>
                {e?.service?.name || ""}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ px: 2, py: 2 }}
            >
              <Stack
                direction={"row"}
                alignItems={"flex-start"}
                gap={2}
                sx={{ width: "100%" }}
              >
                <Stack
                  pb={1.5}
                  gap={1}
                  sx={{
                    borderRadius: "10px",
                    width: "150px",
                    backgroundColor: "#F5F5F5",
                    flexShrink: "0",
                    overflow: "hidden",
                  }}
                >
                  <Stack sx={{ height: "70px", width: "100%" }}>
                    <img
                      src={`${imgUrl}${e?.service?.mobile_image}`}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Stack>
                  <Typography
                    className="mainPara"
                    sx={{
                      color: "#333333",
                      mx: 1,
                      textAlign: "center",
                    }}
                  >
                    {e?.service?.name || ""}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{
                    width: "100%",
                    height: "auto",
                  }}
                  flexWrap={{
                    xl: "nowrap",
                    lg: "nowrap",
                    md: "wrap",
                    sm: "wrap",
                    xs: "wrap",
                  }}
                >
                  <Stack
                    justifyContent={"space-around"}
                    gap={2}
                    sx={{ height: "100%" }}
                  >
                    <Stack sx={{ height: "100%" }}>
                      {e?.date?.map(({ selected_date, shifts }) => {
                        return (
                          <Stack
                            mt={0.5}
                            flexDirection={"row"}
                            alignItems={"center"}
                            gap={2}
                          >
                            <Stack>
                              {/* <FaCalendarDays color={themeOrange} size={26} /> */}
                              <LuCalendarClock
                                style={{
                                  color: themeOrange,
                                  fontSize: "26px",
                                  flexShrink: "0",
                                }}
                              />
                            </Stack>
                            <Typography
                              className="subpara"
                              sx={{ color: "#333333" }}
                            >
                              {format(selected_date, "EEE, MMM d, yyyy")}
                            </Typography>
                            <Typography className="service_shifts_txt">
                              (
                              {shifts
                                ?.map((shift) => shift?.slice(0, 3))
                                .join(", ")}
                              )
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                    {e?.time && (
                      <Stack
                        direction={"row"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        gap={1}
                      >
                        <MdOutlineWatchLater
                          style={{
                            fontSize: "24px",
                            color: "#F15A24",
                            flexShrink: 0,
                          }}
                        />
                        <Typography
                          className="subpara"
                          sx={{ color: "#333333" }}
                        >
                          {e?.time?.length > 30
                            ? `${e?.time?.substring(0, 30)}...`
                            : e?.time}
                        </Typography>
                      </Stack>
                    )}
                    <Stack
                      direction={"row"}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                      gap={1}
                    >
                      <MdOutlineLocationOn
                        style={{
                          fontSize: "24px",
                          color: "#F15A24",
                          flexShrink: 0,
                        }}
                      />
                      <Typography className="subpara" sx={{ color: "#333333" }}>
                        {e?.location || ""}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#F15A24",
                      color: "#F15A24",
                      borderRadius: "15px",
                      px: 3,
                      py: 1,
                      backgroundColor: "white",
                      ":hover": {
                        backgroundColor: "white",
                        borderColor: "#F15A24",
                      },
                      textTransform: "none",
                      mt: 1,
                    }}
                    onClick={() => handleViewDetails(e?.id)}
                  >
                    {isSelected ? "Hide Detail" : "View Detail"}
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            {isSelected && (
              <Stack
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  px: 1,
                }}
              >
                {/* Job Description */}
                <Stack gap={1} px={2} py={2}>
                  <Typography
                    className="mainHeading"
                    sx={{ color: "#333333", fontSize: "20px" }}
                  >
                    Job Description
                  </Typography>
                  <Stack
                    sx={{
                      borderRadius: "15px",
                      border: "1px solid #F1F1F1",
                    }}
                    px={2}
                    py={2}
                  >
                    <Typography className="mainPara" sx={{ color: "#868181" }}>
                      {e?.description || ""}
                    </Typography>
                  </Stack>
                </Stack>

                {/* Timing Constraints */}
                <Stack gap={1} px={2} py={2}>
                  <Typography
                    className="mainHeading"
                    sx={{ color: "#333333", fontSize: "20px" }}
                  >
                    Timing Constraints
                  </Typography>
                  <Stack
                    sx={{
                      borderRadius: "15px",
                      border: "1px solid #F1F1F1",
                    }}
                    px={2}
                    py={2}
                  >
                    <Typography
                      className="mainPara"
                      sx={{
                        color: "#868181",
                        textAlign: "start",
                      }}
                    >
                      {e?.time || ""}
                    </Typography>
                  </Stack>
                </Stack>

                {e?.vendor?.first_name && (
                  <Stack gap={1} px={2} py={2}>
                    <Typography
                      className="mainHeading"
                      sx={{ color: "#333333", fontSize: "20px" }}
                    >
                      Vendor Detail
                    </Typography>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        borderRadius: "15px",
                        border: "1px solid #F1F1F1",
                      }}
                      px={2}
                      py={2}
                    >
                      <Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <Typography
                            className="mainPara"
                            sx={{
                              color: "black",
                              fontWeight: "600 !important",
                              fontSize: "16px !important",
                            }}
                          >
                            Name :
                          </Typography>
                          <Typography
                            className="mainPara"
                            sx={{ color: "#868181" }}
                          >
                            {`${e?.vendor?.first_name || ""} ${
                              e?.vendor?.last_name || ""
                            }`}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <Typography
                            className="mainPara"
                            sx={{
                              color: "black",
                              fontWeight: "600 !important",
                              fontSize: "16px !important",
                            }}
                          >
                            Email :
                          </Typography>
                          <Typography
                            // className="mainPara"
                            sx={{ color: "#868181" }}
                          >
                            {`${e?.vendor?.email || ""} `}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Button
                        variant="contained"
                        startIcon={
                          <HiMiniChatBubbleLeftRight
                            size={17}
                            sx={{ color: "white" }}
                          />
                        }
                        sx={{
                          borderColor: "#F15A24",
                          borderRadius: "15px",
                          px: 3,
                          py: 1,
                          color: "white",
                          background:
                            "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                          ":hover": {
                            background:
                              "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                            borderColor: "#F15A24",
                          },
                          mx: 2,
                          my: 2,
                        }}
                        onClick={() =>
                          _handleVanderChat(
                            e?.vendor?.id || "",
                            e?.vendor?.first_name || "",
                            e?.vendor?.last_name || "",
                            e?.vendor?.avatar || ""
                          )
                        }
                      >
                        Chat Now
                      </Button>
                    </Stack>
                  </Stack>
                )}

                {/* Multiple Images */}
                <Stack
                  gap={1}
                  className="jobs_images_scrollbar_wrapper"
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    mx: 2,
                  }}
                ></Stack>

                <Grid container spacing={2}>
                  {e?.images?.map((image, i) => {
                    {
                      /* console.log("image",image) */
                    }
                    return (
                      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                        <Stack
                          key={i}
                          direction={"row"}
                          // alignItems={"center"}
                          sx={{
                            height: "100%",
                            border: "1px solid #e0e0e0",
                            borderRadius: "15px",
                          }}
                          gap={2}
                          p={1}
                        >
                          <img
                            src={image?.url}
                            style={{
                              height: "105px",
                              width: "105px",
                              borderRadius: "15px",
                            }}
                          />
                          <Typography
                            className="mainPara"
                            sx={{
                              color: "black",
                              textAlign: "start",
                              // my:2
                            }}
                          >
                            {image?.caption || ""}
                          </Typography>
                        </Stack>
                      </Grid>
                    );
                  })}
                </Grid>

                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  {e?.vendor && (
                    <Button
                      variant="contained"
                      sx={{
                        borderColor: "#F15A24",
                        borderRadius: "15px",
                        px: 3,
                        py: 1,
                        color: "white",
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                        ":hover": {
                          background:
                            "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                          borderColor: "#F15A24",
                        },
                        mx: 2,
                        my: 2,
                        width: "170px",
                      }}
                      onClick={() => handleCancel(e?.id, "Completed")}
                    >
                      {jobStatusLoader && selectedJobStatus === "Completed" ? (
                        <CircularProgress size={20} sx={{ color: "white" }} />
                      ) : (
                        "Complete Job"
                      )}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderColor: "#F15A24",
                      borderRadius: "15px",
                      px: 3,
                      py: 1,
                      color: "white",
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                      ":hover": {
                        background:
                          "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                        borderColor: "#F15A24",
                      },
                      mx: 2,
                      my: 2,
                      width: "170px",
                    }}
                    onClick={() => handleCancel(e?.id, "Cancelled")}
                  >
                    {jobStatusLoader && selectedJobStatus === "Cancelled" ? (
                      <CircularProgress size={20} sx={{ color: "white" }} />
                    ) : (
                      "Cancel Job"
                    )}
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ActiveJobsTab;
