import React, { useRef, useState } from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Slider.css";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { imgUrl } from "../../utils/imageUrl";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../common/ButtonComp";
import useWindowDimensions from "../../utils/hooks/windowDimensions";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { setJobsImages } from "../../store/slices/services_slice";
import { service_booking_keys, steps_constants } from "../../store/constants";

const CustomSlider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { get_all_services_data } = useSelector((state) => state.serviceManage);
  const _handleClick = (item) => {
    navigate(`/service-details/${item}`);
    dispatch(setJobsSteps({ value: null }));
    localStorage.removeItem(steps_constants.JOBS_STEP);
    localStorage.removeItem(service_booking_keys.DATE);
    localStorage.removeItem(service_booking_keys.TIME);
    localStorage.removeItem(service_booking_keys.DESCRIPTION);
    localStorage.removeItem(service_booking_keys.LOCATION);
    localStorage.removeItem(service_booking_keys.PRICE);
    localStorage.removeItem(service_booking_keys.IMAGES);
  };

  return (
    <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      speed={600}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      {get_all_services_data?.percentage
        ?.filter(({ percentage }) => percentage > 0)
        .sort((a, b) => b.percentage - a.percentage)
        .map(({ percentage, servivce }, i) => {
          console.log(">>>>>>>>>", `${imgUrl}${servivce?.mobile_image}`);
          return (
            <SwiperSlide key={i}>
              <Stack
                justifyContent={"center"}
                alignItems={"flex-start"}
                className="slider_img_wrapper"
              >
                <Box
                  className="slider_img_box"
                  sx={{
                    background: `url(${imgUrl}${
                      servivce?.mobile_image || ""
                    }) no-repeat center center/cover`,
                  }}
                ></Box>
                <Box className="slider_content_box">
                  <Typography
                    sx={{ fontSize: "25px", fontWeight: "200", color: "white" }}
                  >
                    Ready to Book Your
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "25px",
                      fontWeight: "700",
                      color: "#F15A24",
                      textTransform: "capitalize",
                    }}
                  >
                    {servivce?.name || ""}
                  </Typography>
                  <ButtonComp
                    onClick={() => _handleClick(servivce?.slug)}
                    label={"Book Now"}
                    style={{
                      borderRadius: "6px",
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                      padding: "10px 50px",
                      width: width < 600 ? "100%" : "auto",
                    }}
                  />
                </Box>
              </Stack>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default CustomSlider;
