import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import "./index.css";
import { AiFillLike } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomLocationSearch from "../IconInput/customInput";
import {
  LOCATION_CONSTANT,
  service_booking_keys,
  SERVICE_DETAIL_PAGE_CONSTANT,
  steps_constants,
} from "../../store/constants";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { themeOrange } from "../../utils/colorTheme";
import logo_white from "../../assets/logo_2.png";
import { GoCheckCircleFill } from "react-icons/go";
import ButtonComp from "../common/ButtonComp";
import { RxCross2 } from "react-icons/rx";
import CustomModal from "../common/CustomModal/CustomModal";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import CustomSelectComp from "../common/selectComp/CustomSelectComp";

const FirstCard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModalOurRates, setOpenModalOurRates] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [location, setLocation] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [selectedCard, setSelectedCard] = useState("lower");
  const [openMemberShipModal, setOpenMemberShipModal] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState({});
  const [fromSelectBox, setFromSelectBox] = useState(false);
    const [isDifferentLocation, setIsDifferentLocation] = useState(false);
  

  const { userAuth, user } = useSelector((state) => state.userAuth);

  useEffect(() => {
    const storedCard = localStorage.getItem(service_booking_keys.PRICE);

    if (storedCard) {
      setSelectedCard(storedCard === "member" ? "lower" : "upper");
    }
  }, [user?.subscription_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const _handleCardSelection = (card) => {
    if (+user?.user_role === 2) {
      setOpenVendorModal(!openVendorModal);
    } else {
      setSelectedCard(card);
      const priceData = card === "lower" ? "member" : "user";
      localStorage.setItem(service_booking_keys.PRICE, priceData);
    }
  };

  const jobs_steps = localStorage.getItem(steps_constants.JOBS_STEP);

  const {
    get_service_by_id_status,
    get_service_by_id_data,
    get_service_by_id_error,
  } = useSelector((state) => state.serviceManage);

  const storedUserRoleString = +localStorage.getItem("user_role");

  const _handleStart = () => {
    const priceData = selectedCard === "upper" ? "user" : "member";
    localStorage.setItem(service_booking_keys.PRICE, priceData);
  };

  useEffect(() => {
    const _getLocations =
      JSON.parse(localStorage.getItem(LOCATION_CONSTANT))?.reverse() || [];
    setAllLocations([..._getLocations]);

    // setLocation(_getLocations?.[0]);
    setDefaultLocation({
      value: _getLocations?.[0],
      label: _getLocations?.[0],
    });
    console.log("location>>>>>", _getLocations);
    if (_getLocations?.length > 0) {
      setFromSelectBox(true);
      setLocation(_getLocations?.[0]);
    }

    if (allLocations?.length === 0) {
      setIsDifferentLocation(0);
    }
  }, []);

  const options = allLocations?.map((location) => ({
    value: location,
    label: location,
  }));

  const _handleClick = async () => {
    setLocationErr("");
    // if (!userAuth) {
    //   setOpenLoginModal(!openLoginModal);
    //   return;
    // }

    try {
      if (!location) {
        setLocationErr("Please select a location.");
        return;
      } else {
        if (defaultLocation?.value && location && fromSelectBox) {
          if (selectedCard === "lower") {
            setOpenMemberShipModal(!openMemberShipModal);
            _handleStart();
            localStorage.setItem(
              service_booking_keys.LOCATION,
              options?.[0]?.value
            );
          } else {
            _handleStart();
            localStorage.setItem(
              service_booking_keys.LOCATION,
              options?.[0]?.value
            );
            dispatch(
              setJobsSteps({
                value: "1",
              })
            );
          }
        } else {
          const results = await geocodeByPlaceId(location);

          if (!results || results.length === 0) {
            setLocationErr("Please select a valid location.");
            return;
          }

          const addressComponents = results[0].address_components;

          let streetNumber = "";
          let formattedAddress = results[0].formatted_address;

          // Loop through address components to find the street_number
          addressComponents.forEach((component) => {
            const types = component.types;
            if (types.includes("street_number")) {
              streetNumber = component.long_name;
            }
          });

          // If the street number is missing, show an error
          if (!streetNumber) {
            setLocationErr("Street number is missing in the address.");
            return;
          }

          setLocationErr("");
          // if (storedUserRoleString === 3) {
          //   _handleStart();
          //   localStorage.setItem(service_booking_keys.LOCATION, formattedAddress);
          //   dispatch(
          //     setJobsSteps({
          //       value: "1",
          //     })
          //   );
          // }
          console.error("formattedAddress:", formattedAddress);

          const locationExists = allLocations.includes(formattedAddress);

          const updatedLocations = locationExists
            ? [
                formattedAddress,
                ...allLocations.filter(
                  (location) => location !== formattedAddress
                ),
              ]
            : [formattedAddress, ...allLocations];

          localStorage.setItem(
            LOCATION_CONSTANT,
            JSON.stringify(updatedLocations)
          );
          setLocation(updatedLocations);
          if (selectedCard === "lower") {
            setOpenMemberShipModal(!openMemberShipModal);
            _handleStart();
            localStorage.setItem(
              service_booking_keys.LOCATION,
              formattedAddress
            );
          } else {
            _handleStart();
            localStorage.setItem(
              service_booking_keys.LOCATION,
              formattedAddress
            );
            dispatch(
              setJobsSteps({
                value: "1",
              })
            );
          }

          setLocationErr("");
        }
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
      setLocationErr("Error fetching location details.");
    }
  };
  const _handleSelectedLocation = (value) => {
    console.log("values", value);
    setLocation(value.value.place_id);
    setFromSelectBox(false);
  };

  useEffect(() => {
    if (allLocations?.length === 0) {
      setIsDifferentLocation(true);
    }
  }, [allLocations]);

  const _getAddress = async () => {
    try {
      const address = await geocodeByPlaceId(location);
      let formattedAddress = address[0].formatted_address;

      console.log("formattedAddress", formattedAddress);
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  useEffect(() => {
    if (location !== "") {
      setLocationErr("");
    }
  }, [location]);

  const _handleSelectBoxChange = (e) => {
    const locationExists = allLocations.includes(e.value);

    const updatedLocations = locationExists
      ? [e.value, ...allLocations.filter((location) => location !== e.value)]
      : [e.value, ...allLocations];
    setAllLocations(updatedLocations);
    setDefaultLocation(e);
    setLocation(e.value);
    setFromSelectBox(true);
    localStorage.setItem(LOCATION_CONSTANT, JSON.stringify(updatedLocations));
  };

  const howWorksArr = [
    "Tell us what you need done and select a date and time that works for you.",
    "Submit your job request and get matched with a Certified Done Pro in minutes!",
    "Chat with your Pro to discuss any further details before the job is set to begin.",
  ];

  return (
    <div>
      <Stack
        sx={{
          paddingX: {
            xl: 3,
            lg: 3,
            md: 3,
            sm: 3,
            xs: 0,
          },
          paddingTop: 5,
          backgroundColor: "white",
        }}
      >
        <Typography className="service-name">
          {get_service_by_id_data?.service?.name || ""}
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          sx={{
            paddingTop: 3,
          }}
          gap={2}
        >
          <div className="review-btn-container">
            <div style={{ marginTop: "5px" }}>
              <FaStar size={20} color="white" />
            </div>
            <Typography className="review-percent-text">
              {get_service_by_id_data?.avg_rating?.toLocaleString() || ""}
            </Typography>
          </div>
          <Typography className="job-review">
            of {get_service_by_id_data?.reviews?.length?.toLocaleString() || ""}{" "}
            job reviews
          </Typography>
        </Stack>
        <Typography mt={3} className="service-sub-name">
          Get a confirmed job in minutes
        </Typography>
        <Typography className="job-details" mt={1}>
          {get_service_by_id_data?.service?.description || ""}
        </Typography>

        {/* Selected Prices Services Cards  */}

        <Stack
          sx={{
            paddingX: {
              xl: 5,
              lg: 5,
              md: 5,
              sm: 5,
              xs: 0,
            },
            paddingTop: 3,
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "block",
              xs: "block",
            },
          }}
        >
          {/* <Stack
            flexDirection={"row"}
            alignItems={"flex-start"}
            flexWrap={"wrap"}
            gap={{ xl: 0.5, lg: 0, md: 0, sm: 0, xs: 0 }}
          >
            <Typography className="questions-about">
              Questions about our rates?
            </Typography>
            <Typography
              className="learn-more"
              onClick={() => setOpenModalOurRates(!openModalOurRates)}
            >
              Learn more
            </Typography>
          </Stack> */}

          {/* >>>>>>>>> Questions Modal */}
          {/* <CustomModal
            isOpen={openModalOurRates}
            setIsOpen={() => setOpenModalOurRates(!openModalOurRates)}
          >
            <Stack
              height={"60vh"}
              width={{
                xl: "50vw",
                lg: "50vw",
                md: "50vw",
                sm: "50vw",
                xs: "90vw",
              }}
              sx={{ overflow: "auto", backgroundColor: "white" }}
              alignItems={"center"}
              p={1}
            >
              <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
                <IconButton
                  onClick={() => setOpenModalOurRates(!openModalOurRates)}
                >
                  <RxCross2 size={20} sx={{ color: "black" }} />
                </IconButton>
              </Stack>

              <Stack gap={2} px={1}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "black",
                    fontSize: "25px",
                    fontWeight: "700",
                    lineHeight: "24px",
                  }}
                >
                  Questions about our rates?
                </Typography>
                <Typography
                  sx={{ color: "black", fontSize: "13px", fontWeight: "500" }}
                >
                  We've already done the work of calling around! Jiffy rates and
                  job minimums are based on industry averages per category.
                </Typography>

                {questionsAboutData.map(({ question, answer }, i) => {
                  return (
                    <Stack key={i} gap={1}>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "17px",
                          fontWeight: "600",
                          lineHeight: "24px",
                        }}
                      >
                        {question || ""}
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      >
                        {answer || ""}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </CustomModal> */}

          <Typography my={2} className="choose_rate_txt">
            Choose your rate:
          </Typography>

          <Grid
            container
            alignItems={"stretch"}
            justifyContent={"center"}
            spacing={1}
            mt={1}
          >
            {/* Lower Card */}
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Stack
                onClick={() => _handleCardSelection("lower")}
                sx={{
                  borderRadius: "10px",
                  border: `3px solid ${
                    selectedCard === "lower" ? themeOrange : "transparent"
                  }`,
                  background:
                    "var(--Blue-colour, linear-gradient(180deg, #162f77 0%, #031444 100%))",
                  overflow: "hidden",
                  cursor: "pointer",
                  height: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexWrap={"nowrap"}
                  py={1}
                  px={2}
                >
                  <img
                    src={logo_white}
                    style={{
                      width: "40px",
                      objectFit: "contain",
                    }}
                    alt="Logo"
                  />
                  <Typography className="member_txt" sx={{ flexShrink: 0 }}>
                    {" "}
                    + Member
                  </Typography>
                </Stack>

                <Divider sx={{ bgcolor: "#86838347" }} />

                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  py={1}
                  px={2}
                  sx={{ width: "100%" }}
                  gap={1}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexShrink={0}
                    gap={1}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "grey",
                        textDecorationLine: "line-through",
                      }}
                      className="number_family"
                    >
                      $
                      {get_service_by_id_data?.service?.price?.toLocaleString() ||
                        ""}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bolder",
                        color: themeOrange,
                      }}
                      className="number_family"
                    >
                      $
                      {(get_service_by_id_data?.service?.price?.toLocaleString() -
                        25 <
                      0
                        ? "0"
                        : get_service_by_id_data?.service?.price?.toLocaleString() -
                          25) || ""}
                    </Typography>
                  </Stack>

                  <Typography
                    sx={{
                      fontSize: {
                        xl: "18px",
                        lg: "18px",
                        md: "18px",
                        sm: "18px",
                        xs: "15px",
                      },
                      color: "white",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {/* For the first{" "} */}
                    {get_service_by_id_data?.service?.additional_text || ""}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#ffffffb5",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    {get_service_by_id_data?.service?.add_price && (
                      <>
                        +
                        <Typography
                          variant="caption"
                          className="number_family"
                          sx={{
                            fontSize: "15px",
                            color: themeOrange,
                            fontWeight: "500",
                          }}
                        >
                          ${get_service_by_id_data?.service?.add_price || ""}
                        </Typography>{" "}
                      </>
                    )}{" "}
                    {get_service_by_id_data?.service?.additional_text_2 || ""}
                  </Typography>

                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      height: "25px",
                      width: "25px",
                      borderRadius: "100%",
                      padding: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: themeOrange,
                      border: `3px solid ${themeOrange}`,
                      flexShrink: 0,
                    }}
                  >
                    {selectedCard === "lower" && (
                      <GoCheckCircleFill size={20} sx={{ flexShrink: "0" }} />
                    )}
                  </IconButton>
                </Stack>

                <Divider sx={{ bgcolor: "#86838347" }} />

                <Stack
                  direction={"row"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                  p={2}
                  gap={2}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#ffffffd9",
                      textAlign: "center",
                    }}
                  >
                    Only{" "}
                    <Typography variant="caption" className="number_family">
                      $10
                    </Typography>
                    /month, members get{" "}
                    <Typography variant="caption" className="number_family">
                      $25
                    </Typography>{" "}
                    off every job, and more benefits!
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            {/* Upper Card */}
            {!user?.subscription_id && (
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Stack
                  onClick={() => _handleCardSelection("upper")}
                  sx={{
                    borderRadius: "10px",
                    border: `3px solid ${
                      selectedCard === "upper" ? themeOrange : "transparent"
                    }`,
                    backgroundColor: "rgb(255, 226, 216)",
                    overflow: "hidden",
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    py={1}
                    px={2}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: themeOrange,
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      Regular
                    </Typography>
                  </Stack>

                  <Divider />

                  <Stack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    py={1}
                    px={2}
                    gap={1}
                  >
                    <Stack alignItems={"center"}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bolder",
                          color: themeOrange,
                        }}
                        className="number_family"
                      >
                        $
                        {get_service_by_id_data?.service?.price?.toLocaleString() ||
                          ""}
                      </Typography>
                    </Stack>

                    <Typography
                      sx={{
                        fontSize: {
                          xl: "18px",
                          lg: "18px",
                          md: "18px",
                          sm: "18px",
                          xs: "15px",
                        },
                        color: "black",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      For the first{" "}
                      {get_service_by_id_data?.service?.additional_text || ""}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "black",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {get_service_by_id_data?.service?.add_price && (
                        <>
                          +
                          <Typography
                            variant="caption"
                            className="number_family"
                            sx={{
                              fontSize: "15px",
                              color: themeOrange,
                              fontWeight: "500",
                            }}
                          >
                            $
                            {get_service_by_id_data?.service?.add_price?.toLocaleString() ||
                              ""}
                          </Typography>
                        </>
                      )}{" "}
                      {get_service_by_id_data?.service?.additional_text_2 || ""}
                    </Typography>

                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        height: "25px",
                        width: "25px",
                        borderRadius: "100%",
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: themeOrange,
                        border: `3px solid ${themeOrange}`,
                        flexShrink: 0,
                      }}
                    >
                      {selectedCard === "upper" && (
                        <GoCheckCircleFill size={20} sx={{ flexShrink: "0" }} />
                      )}
                    </IconButton>
                  </Stack>

                  <Divider />

                  <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    p={2}
                    gap={2}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      No Member benefits,no monthly payments. Regular job rates
                      apply.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>

          {jobs_steps === null && (
            <Stack
              sx={{
                paddingX: +user?.user_role === 2 ? 0 : 2,
                mt: 5,
              }}
            >
              {user && user?.user_role === 2 ? (
                <Stack
                  sx={{
                    border: "1px solid #ff9800", // Warning color border
                    borderRadius: "8px",
                    padding: "6px 10px",
                    backgroundColor: "#fff8e1", // Light warning background color
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ color: "#6a6a6a", fontSize: "14px" }}
                  >
                    You must be a registered user to book this service. To enjoy
                    secure bookings and excellent services, please create a user
                    account.
                    {/* <Typography
                      onClick={() => navigate("/signup-user")}
                      variant="caption"
                      sx={{
                        color: themeOrange,
                        fontSize: "14px",
                        cursor: "pointer",
                        ":hover": {
                          textDecorationLine: "underline",
                        },
                      }}
                    >
                      click here
                    </Typography> */}
                    {/* <Typography
                    onClick={() => navigate("")}
                    variant="caption"
                    sx={{
                      color: "#ff9800",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Click here{" "}
                  </Typography> */}
                  </Typography>
                  {/* <Button
          variant="outlined"
          sx={{ marginTop: "16px", borderColor: "#ff9800", color: "" }}
        ></Button> */}
                </Stack>
              ) : (
                <>
                  {" "}
                  <Stack>
                    {allLocations?.length > 0 && isDifferentLocation ? (
                      <Stack>
                        <Typography
                          className="label-text"
                          style={{ marginBottom: "10px" }}
                        >
                          Where do you need a Done?
                        </Typography>
                        <CustomSelectComp
                          options={options}
                          value={options[0]}
                          onChange={(e) => _handleSelectBoxChange(e)}
                          isMulti={false}
                          defaultstyle
                          customStyles={{
                            control: {
                              borderColor: "blue",
                              height: "54px",
                              borderColor: themeOrange,
                              borderRadius: "6px",
                            },
                            multiValueLabel: { fontSize: "14px" },
                          }}
                          customColor={themeOrange}
                        />
                      </Stack>
                    ) : (
                      <CustomLocationSearch
                        textCondition={true}
                        setSelectedLocationProps={_handleSelectedLocation}
                        // defaultValue={"Default Location"}
                      />
                    )}

                    {locationErr && (
                      <Typography sx={{ color: "#d32f2f", fontSize: "12px" }}>
                        {locationErr || ""}
                      </Typography>
                    )}
                    {allLocations?.length > 0 && (
                      <Typography
                        onClick={() =>
                          setIsDifferentLocation(!isDifferentLocation)
                        }
                        variant="button"
                        sx={{
                          color: "#3391ff",
                          fontSize: "15px",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontWeight: "400",
                          width: "fit-content",
                        }}
                      >
                        {allLocations?.length > 0 && isDifferentLocation
                          ? "Enter a different address"
                          : "Use an existing address"}
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    onClick={_handleClick}
                    mt={3}
                    mb={2}
                    className="get-start-btn"
                    sx={{
                      background:
                        "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%)) !important",
                    }}
                  >
                    Get Started
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </Stack>

        {/* Other content of the service detail page  */}

        <Divider sx={{ mt: "20px" }} />
        <Typography mt={3} className="service-sub-name">
          Customers use this service for
        </Typography>
        <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1} mt={2}>
          {get_service_by_id_data?.service?.used_for?.map((e, i) => {
            return (
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
                gap={1}
              >
                <Stack>
                  <FaCheck color="#a8a8a8" size={20} />
                </Stack>
                <Typography className="category-text">{e}</Typography>
              </Stack>
            );
          })}
        </Stack>

        <Stack direction={"row"} alignItems={"flex-start"} gap={0.4} mt={3}>
          <Typography className="category-text">
            Not sure if this is the right service for you?
          </Typography>
          <Typography
            onClick={() => navigate("/help-center")}
            className="category-text_link"
          >
            Help center
          </Typography>
        </Stack>

        <Divider sx={{ mt: "20px" }} />

        <Stack
          sx={{
            padding: 3,
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <Stack sx={{ width: "100%" }} my={2}>
            <Typography className="how-its-works">How it works</Typography>
          </Stack>
          <Grid
            container
            direction={"row"}
            alignItems={"stretch"}
            justifyContent={"space-between"}
            spacing={1}
          >
            {howWorksArr.map((txt, i) => {
              return (
                <Grid key={i} item xl={4} lg={4} md={6} sm={6} xs={12}>
                  <Stack
                    key={i}
                    direction={"row"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    gap={2}
                    sx={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "10px",
                      padding: 3,
                      height: "100%",
                    }}
                  >
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        backgroundColor: "black",
                        padding: "5px",
                        borderRadius: "100%",
                        flexShrink: 0,
                        color: "white",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      {i + 1}
                    </Stack>
                    <Typography
                      className="how-its-works-discription"
                      mt={1}
                      sx={{
                        textAlign: "start !important",
                        fontSize: "11px !important",
                      }}
                    >
                      {txt}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>

        <Divider sx={{ marginTop: "35px" }} />

        <Stack my={3} gap={1}>
          <Typography className="service-sub-name">
            Certified Done Pros
          </Typography>

          <Typography className="certified-details">
            All Done Pros are insured and qualified, highly rated by our
            customers, and background-checked. Pros must maintain a minimum 90%
            approval rating from customers.
          </Typography>
        </Stack>
      </Stack>

      {/* >>>>>>>>> Member Ship Modal */}
      <CustomModal
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
        modal_inner_Style={{
          width: {
            xl: "30vw !important",
            lg: "40vw !important",
            md: "40vw !important",
            sm: "60vw !important",
            xs: "80vw !important",
          },
        }}
      >
        <Stack
          sx={{
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton onClick={() => setOpenModal(!openModal)}>
              <RxCross2 size={20} sx={{ color: "black" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} px={1} sx={{ width: "100%" }}>
            <Typography
              variant="caption"
              sx={{
                color: "black",
                fontSize: {
                  xl: "16px",
                  lg: "16px",
                  md: "16px",
                  sm: "16px",
                  xs: "14px",
                },
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              You need to subscribe to the membership to unlock this benefit
              priced at{" "}
              <Typography
                variant="caption"
                sx={{
                  color: themeOrange,
                  fontWeight: "700",
                  fontSize: {
                    xl: "16px",
                    lg: "16px",
                    md: "16px",
                    sm: "16px",
                    xs: "14px",
                  },
                }}
              >
                {" "}
                $
                {(get_service_by_id_data?.service?.price?.toLocaleString() -
                  25 <
                0
                  ? "0"
                  : get_service_by_id_data?.service?.price?.toLocaleString() -
                    25) || ""}{" "}
              </Typography>
              Would you like to proceed to become a member?
            </Typography>

            <Stack
              gap={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <ButtonComp
                onClick={() => {
                  navigate("/member-ship");
                  localStorage.setItem(
                    SERVICE_DETAIL_PAGE_CONSTANT.from,
                    "FROM_SERVICE_DETAIL"
                  );
                  localStorage.setItem(
                    SERVICE_DETAIL_PAGE_CONSTANT.service_id,
                    id
                  );
                }}
                label={"Yes"}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  background:
                    "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                }}
              />
              <ButtonComp
                onClick={() => setOpenModal(!openModal)}
                label={"No"}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  background:
                    "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>

      {/* Login Modal */}
      <CustomModal
        isOpen={openLoginModal}
        setIsOpen={() => setOpenLoginModal(!openLoginModal)}
        modal_inner_Style={{
          width: {
            xl: "30vw !important",
            lg: "40vw !important",
            md: "40vw !important",
            sm: "60vw !important",
            xs: "80vw !important",
          },
        }}
      >
        <Stack
          sx={{
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton onClick={() => setOpenLoginModal(!openLoginModal)}>
              <RxCross2 size={20} sx={{ color: "black" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} px={1} sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: {
                  xl: "16px",
                  lg: "16px",
                  md: "16px",
                  sm: "16px",
                  xs: "14px",
                },
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              Please log in to continue.
            </Typography>

            <Stack
              gap={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <ButtonComp
                onClick={() => {
                  navigate("/login");
                  localStorage.setItem(
                    SERVICE_DETAIL_PAGE_CONSTANT.from,
                    "FROM_SERVICE_DETAIL"
                  );
                  localStorage.setItem(
                    SERVICE_DETAIL_PAGE_CONSTANT.service_id,
                    id
                  );
                }}
                label={"Login"}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  background:
                    "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                }}
              />
              <ButtonComp
                onClick={() => setOpenLoginModal(!openLoginModal)}
                label={"Cancel"}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  background:
                    "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>

      {/* Vendor Warning Modal */}
      <CustomModal
        isOpen={openVendorModal}
        setIsOpen={() => setOpenVendorModal(!openVendorModal)}
        modal_inner_Style={{
          width: {
            xl: "30vw !important",
            lg: "40vw !important",
            md: "40vw !important",
            sm: "60vw !important",
            xs: "80vw !important",
          },
        }}
      >
        <Stack
          sx={{
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton onClick={() => setOpenVendorModal(!openVendorModal)}>
              <RxCross2 size={20} sx={{ color: "black" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} px={1} sx={{ width: "100%" }}>
            <Typography
              sx={{
                color: "black",
                fontSize: {
                  xl: "16px",
                  lg: "16px",
                  md: "16px",
                  sm: "16px",
                  xs: "14px",
                },
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              You must be a registered user to book this service. To enjoy
              secure bookings and excellent services, please create an account
              or log in now.
            </Typography>

            <Stack
              gap={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <ButtonComp
                onClick={() => setOpenVendorModal(false)}
                label={"Ok"}
                style={{
                  width: "auto",
                  height: "auto",
                  borderRadius: "15px",
                  background:
                    "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>

      {/* <!--------- Member ship modal for selected member ship card ---------!> */}
      <CustomModal
        isOpen={openMemberShipModal}
        setIsOpen={() => setOpenMemberShipModal(!openMemberShipModal)}
        modal_inner_Style={{
          width: {
            xl: "auto !important",
            lg: "auto !important",
            md: "auto !important",
            sm: "auto !important",
            xs: "85vw !important",
          },
        }}
      >
        <Stack
          sx={{
            overflow: "auto",
            background: "linear-gradient(180deg, #273350, #2f4f94)",
            borderRadius: "10px",
          }}
          alignItems={"center"}
          p={1}
        >
          <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
            <IconButton
              onClick={() => setOpenMemberShipModal(!openMemberShipModal)}
            >
              <RxCross2 size={20} style={{ color: "white" }} />
            </IconButton>
          </Stack>

          <Stack gap={2} sx={{ width: "100%", padding: "35px 24px" }}>
            <Stack alignItems={"center"}>
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                  fontSize: {
                    xl: "15px",
                    lg: "15px",
                    md: "15px",
                    sm: "14px",
                    xs: "14px",
                  },
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                Thanks for choosing{" "}
                <Typography
                  variant="caption"
                  sx={{
                    color: themeOrange,
                    fontSize: {
                      xl: "17px",
                      lg: "17px",
                      md: "17px",
                      sm: "17px",
                      xs: "15px",
                    },
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  Done+
                </Typography>
                !
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                  fontSize: {
                    xl: "15px",
                    lg: "15px",
                    md: "15px",
                    sm: "14px",
                    xs: "14px",
                  },
                  fontWeight: "300",
                  lineHeight: "24px",
                }}
              >
                Membership for just
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: themeOrange,
                  fontSize: {
                    xl: "15px",
                    lg: "15px",
                    md: "15px",
                    sm: "14px",
                    xs: "14px",
                  },
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
              >
                $10/month. Cancel anytime.
              </Typography>
            </Stack>

            <Stack
              // alignItems={"center"}
              // justifyContent={"center"}
              sx={{
                borderRadius: "7px",
                backgroundColor: "rgba(0,0,0,.25)",
                padding: "10px 15px",
              }}
              // gap={2}
            >
              <Stack gap={1} direction={"row"} alignItems={"center"}>
                <FaCheck style={{ color: themeOrange, fontSize: "16px" }} />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xl: "13px",
                      lg: "13px",
                      md: "13px",
                      sm: "13px",
                      xs: "12px",
                    },
                    fontWeight: "200",
                    lineHeight: "24px",
                  }}
                >
                  Save $25 on every Done Job
                </Typography>
              </Stack>
              <Stack gap={1} direction={"row"} alignItems={"center"}>
                <FaCheck style={{ color: themeOrange, fontSize: "16px" }} />
                <Typography
                  sx={{
                    color: "white",
                    fontSize: {
                      xl: "13px",
                      lg: "13px",
                      md: "13px",
                      sm: "13px",
                      xs: "12px",
                    },
                    fontWeight: "200",
                    lineHeight: "24px",
                  }}
                >
                  Extended Warranty
                </Typography>
              </Stack>
            </Stack>

            <Typography
              variant="caption"
              sx={{
                maxWidth: {
                  xl: "20vw !important",
                  lg: "20vw !important",
                  md: "25vw !important",
                  sm: "auto !important",
                  xs: "auto !important",
                },
                textAlign: "center",
                color: "white",
                fontSize: {
                  xl: "13px",
                  lg: "13px",
                  md: "13px",
                  sm: "11px",
                  xs: "11px",
                },
                fontWeight: "200",
                lineHeight: "normal",
              }}
            >
              By signing up, you agree to the Done+ Terms and authorize us to
              charge your default payment method on file. Your Done+ membership
              continues for $10/month until cancelled. You may cancel any time.
            </Typography>

            <Stack gap={2}>
              <ButtonComp
                onClick={() => setOpenMemberShipModal(false)}
                label={"Go Back"}
                className="member_ship_go_back_btn"
              />
              <ButtonComp
                onClick={() => {
                  setOpenMemberShipModal(false);
                  dispatch(
                    setJobsSteps({
                      value: "1",
                    })
                  );
                }}
                label={"Agree & Continue"}
                className="member_ship_agree_continue_btn"
              />
            </Stack>
          </Stack>
        </Stack>
      </CustomModal>
    </div>
  );
};

export default FirstCard;
