import { Divider, IconButton, Stack, SvgIcon } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import logo_text from "../../assets/logo.png";
import { themeOrange } from "../../utils/colorTheme";
import useWindowDimensions from "../../utils/hooks/windowDimensions";
import { mdDownLayout } from "../../utils/helper/mdDownLayoutListener";
import {
  userDashboardRoutes,
  userFromUserSideRoutes,
} from "../../utils/sideRoute";
import { exit_session } from "../../config/apiHandle/apiHandle";
import CustomModal from "../common/CustomModal/CustomModal";
import { RxCross2 } from "react-icons/rx";
import ButtonComp from "../common/ButtonComp";
import { HiOutlineLogout } from "react-icons/hi";

export const UserSideBar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const { width } = useWindowDimensions();
  const { active, hidden, activeChangeHandle } = props;

  const { sideBarHide } = useSelector((state) => state.layout_controler);
  const { user } = useSelector((state) => state.userAuth);

  const { pathname } = useLocation();

  const menuClickHandle = (elem) => {
    if (width < 851) {
      activeChangeHandle();
    }
    navigate(elem?.linkTo);
  };

  const storedUserRoleString = localStorage.getItem("user_role");

  let storedUserRoleNumber;

  try {
    storedUserRoleNumber = parseInt(storedUserRoleString, 10);

    if (isNaN(storedUserRoleNumber)) {
      throw new Error("Invalid number");
    }
  } catch (error) {
    console.error("Error parsing user role:", error);
  }

  // Compare
  const isRoleMatch = storedUserRoleNumber === 2;
  const isRoleMatchTwo = storedUserRoleNumber === 3;

  // const filteredRoutes = userDashboardRoutes.filter((route, index) => {
  //   if (user?.status === 0 || user?.status === 1) {
  //     // Show only Dashboard, Settings, and Logout when status is 0
  //     return (
  //       index === 0 ||
  //       route.caption === "Dashboard" ||
  //       route.caption === "Settings" ||
  //       route.caption === "Logout"
  //     );
  //   }
  //   // Show all routes when status is 1
  //   return true;
  // });

  return (
    <>
      <Stack
        spacing={2}
        className={`sidebar ${active && "active"} ${
          hidden && "hidden-side-bar"
        } ${sideBarHide && "hideSideBar"}`}
        justifyContent={"space-between"}
      >
        <Stack>
          {" "}
          {width > 850 ? (
            <Stack
              direction="row"
              alignItems="center"
              pl={4}
              pt={5}
              pb={12}
              spacing={1}
              sx={{ height: "45px" }}
            >
              <img
                onClick={() => navigate("/")}
                className="content-effect-logo-text"
                src={logo_text}
                style={{ cursor: "pointer" }}
              />
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              paddingX={2}
              pt={15}
              justifyContent="space-between"
              pb={5}
            >
              <Stack
                direction="row"
                alignItems="center"
                mt={2.5}
                mb={2}
                spacing={1}
                sx={{ height: "45px" }}
              >
                <img
                  onClick={() => navigate("/")}
                  className="content-effect"
                  style={{
                    width: "100px",
                    transition: "width 0.35s ease-in-out",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  src={logo_text}
                />
              </Stack>
              <Stack>
                <IconButton onClick={activeChangeHandle} size="large">
                  <MdClose style={{ color: "black" }} />
                </IconButton>
              </Stack>
            </Stack>
          )}
          {/* <!--------- USER FROM USER SIDE ROUTES ---------!> */}
          <Stack sx={{ paddingLeft: width > 850 ? "0px" : "10px" }} spacing={1}>
            {isRoleMatch
              ? React.Children.toArray(
                  userDashboardRoutes?.map((e) => {
                    const {
                      caption,
                      linkTo,
                      element,
                      list_in_sidebar,
                      admin,
                      user,
                      both,
                    } = e;
                    const isActivePath = `/dashboard${e.linkTo}`;
                    return (
                      list_in_sidebar && (
                        <NavLink
                          style={({ isActive }) => ({
                            textDecoration: "inherit",
                            borderRadius: "0px 50px 10px 0px",
                            height: "45px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            color: isActive ? "white" : "black",
                            background:
                              pathname === isActivePath
                                ? "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))"
                                : "white",
                            // boxShadow:
                            //   mdDownLayout(width) &&
                            //   isActive &&
                            //   "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          })}
                          to={`/dashboard${e.linkTo}`}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ paddingLeft: "23px" }}
                          >
                            <SvgIcon
                              style={{
                                paddingRight: "2px",
                                color:
                                  pathname === isActivePath ? "white" : "black",
                              }}
                            >
                              {e.icon}
                            </SvgIcon>

                            <Typography
                              noWrap
                              sx={{
                                color:
                                  pathname === isActivePath ? "white" : "black",
                              }}
                            >
                              {e.caption}
                            </Typography>
                          </Stack>
                        </NavLink>
                      )
                    );
                  })
                )
              : isRoleMatchTwo
              ? React.Children.toArray(
                  userFromUserSideRoutes.map((e) => {
                    const {
                      caption,
                      linkTo,
                      element,
                      list_in_sidebar,
                      admin,
                      user,
                      both,
                    } = e;
                    const isActivePath = `/dashboard${e.linkTo}`;
                    return (
                      list_in_sidebar && (
                        <NavLink
                          style={({ isActive }) => ({
                            textDecoration: "inherit",
                            borderRadius: "0px 50px 10px 0px",
                            height: "45px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            color: isActive ? "white" : "black",
                            background:
                              pathname === isActivePath
                                ? "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))"
                                : "white",
                            // boxShadow:
                            //   mdDownLayout(width) &&
                            //   isActive &&
                            //   "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          })}
                          to={`/dashboard${e.linkTo}`}
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            style={{ paddingLeft: "23px" }}
                          >
                            <SvgIcon
                              style={{
                                paddingRight: "2px",
                                color:
                                  pathname === isActivePath ? "white" : "black",
                              }}
                            >
                              {e.icon}
                            </SvgIcon>

                            <Typography
                              noWrap
                              sx={{
                                color:
                                  pathname === isActivePath ? "white" : "black",
                              }}
                            >
                              {e.caption}
                            </Typography>
                          </Stack>
                        </NavLink>
                      )
                    );
                  })
                )
              : null}
          </Stack>
        </Stack>

        <Stack sx={{ paddingBottom: "25px" }}>
          <Divider sx={{ my: 2 }} />
          <Stack
            direction={"row"}
            alignItems={"center"}
            onClick={() => setOpenModal(true)}
            sx={{
              paddingLeft: "23px",
              cursor: "pointer",
              marginBottom: "20px !important",
            }}
            gap={1}
          >
            <HiOutlineLogout
              style={{ color: "#071D5B", fontSize: "1.5rem", flexShrink: 0 }}
            />
            <Typography
              noWrap
              sx={{
                color: "black",
              }}
            >
              Sign Out
            </Typography>
          </Stack>
        </Stack>

        {/* <!--------- LOGOUT WARNING MODAL ---------!> */}
        <CustomModal
          isOpen={openModal}
          setIsOpen={() => setOpenModal(!openModal)}
        >
          <Stack
            sx={{
              overflow: "auto",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
            alignItems={"center"}
            p={1}
          >
            <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
              <IconButton
                onClick={() => {
                  setOpenModal(!openModal);
                }}
              >
                <RxCross2 size={20} sx={{ color: "black" }} />
              </IconButton>
            </Stack>

            <Stack
              gap={2}
              px={1}
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  margin:"0px 20px 20px 0px"
                }}
              >
                Are you sure you want to logout?
              </Typography>

              <Stack
                gap={2}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <ButtonComp
                  onClick={() => exit_session()}
                  label={"Yes"}
                  style={{
                    width: "auto",
                    height: "auto",
                    borderRadius: "15px",
                    background:
                      "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                  }}
                />
                <ButtonComp
                  onClick={() => {
                    setOpenModal(!openModal);
                  }}
                  label={"No"}
                  style={{
                    width: "auto",
                    height: "auto",
                    borderRadius: "15px",
                    background:
                      "var(--Orange, linear-gradient(180deg, #F15A24 0%, #C53F10 100%))",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </CustomModal>
      </Stack>
    </>
  );
};
