import React, { useEffect } from "react";
import { ServicesCard } from "../../component/common/Card/ServicesCard/ServicesCard";
import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { imgUrl } from "../../utils/imageUrl";
import { get_services } from "../../services/services";
import { asyncStatus } from "../../utils/asyncStatus";
import { themeOrange } from "../../utils/colorTheme";
import { setJobsSteps } from "../../store/slices/ui_control_slice";
import { setJobsImages } from "../../store/slices/services_slice";
import { service_booking_keys, steps_constants } from "../../store/constants";


const SecondSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_all_services_status, get_all_services_data } = useSelector(
    (state) => state.serviceManage
  );

  const storedUserRoleString = +localStorage.getItem("user_role");

  const getServiceLoader = get_all_services_status === asyncStatus.LOADING;

  const _handleClick = (item) => {
    navigate(`/service-details/${item}`);
    dispatch(setJobsSteps({ value: null }));
    localStorage.removeItem(steps_constants.JOBS_STEP);
    localStorage.removeItem(service_booking_keys.DATE);
    localStorage.removeItem(service_booking_keys.TIME);
    localStorage.removeItem(service_booking_keys.DESCRIPTION);
    localStorage.removeItem(service_booking_keys.LOCATION);
    localStorage.removeItem(service_booking_keys.PRICE);
    localStorage.removeItem(service_booking_keys.IMAGES);
  };

  useEffect(() => {
    dispatch(get_services());
  }, []);

  return (
    <div className="main-container-second-section">
      <Container maxWidth="xl">
        <Typography className="second-section-heading">
          Our <span style={{ color: "#F15A24" }}>Services</span>
        </Typography>
        <div className="card-main-container">
          {getServiceLoader ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ width: "100%", height: "20vh" }}
            >
              <CircularProgress size={30} sx={{ color: themeOrange }} />
            </Stack>
          ) : (
            <Grid container spacing={4} justifyContent={"center"}>
              {get_all_services_data?.services?.map((e, i) => {
                return (
                  <Grid item xl={2} lg={2} md={3} sm={4} xs={11}>
                    <ServicesCard
                      key={i}
                      src={`${imgUrl}${e?.image || ""}`}
                      heading={e.name}
                      sub_heading={e.description}
                      onClick={() => _handleClick(e.slug)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
        {/* <Stack mt={5} alignItems={"center"} justifyContent={"center"}>
          <CustomButton onClick={() => navigate("/services")}>
            See More...
          </CustomButton>
        </Stack> */}
      </Container>
    </div>
  );
};

export default SecondSection;
