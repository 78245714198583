import React, { useEffect } from "react";
import css from "./PrivacyPolicy.module.css";
import Footer from "../../screens/Footer/Footer";
import { Container, Divider, Stack, Typography } from "@mui/material";
import Navbar from "../../component/navbar/Navbar";
import { themeOrange } from "../../utils/colorTheme";

const PrivacyPolicy = () => {
  const privacyData = [
    {
      title: "Information We Collect",
      subData: [
        {
          subTitle: "Personal Information",
          subDescription: (
            <>
              We may collect{" "}
              <span style={{ fontWeight: "600" }}>"personal information"</span>{" "}
              that you provide to us directly, such as your name, email address,
              phone number, postal address, payment details, and any other
              information you choose to provide when you:
            </>
          ),
          subPoints: [
            "Register for an account",
            "Make a purchase",
            "Subscribe to our newsletter",
            "Contact customer support",
            "Participate in surveys or promotions",
          ],
        },
        {
          subTitle: "Usage Data",
          subDescription: (
            <>
              We automatically collect information about your interaction with
              our <span style={{ fontWeight: "600" }}>"Site"</span> and{" "}
              <span style={{ fontWeight: "600" }}>"Services"</span>, including:
            </>
          ),
          subPoints: [
            "IP address",
            "Browser type and version",
            "Device type",
            "Operating system",
            "Pages visited and time spent on each page",
            "Referral source",
            "Interaction with content and advertisements",
          ],
        },
        {
          subTitle: "Cookies and Tracking Technologies",
          subDescription: (
            <>
              We use <span style={{ fontWeight: "600" }}>"cookies"</span> and
              similar technologies to track user activity and collect data.
              Cookies help us enhance user experience, analyse Site usage, and
              target advertisements. You can manage cookie preferences through
              your browser settings, but disabling cookies may affect Site
              functionality.
            </>
          ),
          subPoints: [],
        },
      ],
    },
    {
      title: "How We Use Your Information",
      subData: [
        {
          subTitle: "To Provide and Improve Services",
          subDescription: `We use your information to:`,
          subPoints: [
            "Process transactions and deliver Services",
            "Personalize your experience on our Site",
            "Improve Site functionality and Service offerings",
            "Respond to inquiries and provide customer support",
          ],
        },

        {
          subTitle: "Communication",
          subDescription: `We may use your contact information to:`,
          subPoints: [
            "Send transactional emails related to your account or orders",
            "Provide updates, newsletters, and promotional materials (you can opt-out at any time)",
            "Notify you of changes to our Services, terms, or policies",
          ],
        },
        {
          subTitle: "Legal Compliance",
          subDescription: `We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.`,
          subPoints: [],
        },
      ],
    },
    {
      title: "Information Sharing and Disclosure",
      subData: [
        {
          subTitle: "Service Providers:",
          subDescription: (
            <>
              We may share your information with{" "}
              <span style={{ fontWeight: "600" }}>"Third Party Service"</span>{" "}
              providers who perform services on our behalf, such as payment
              processing, data analysis, email delivery, hosting services, and
              customer support. These providers are contractually obligated to
              protect your information and use it only for the purposes for
              which we disclose it to them.
            </>
          ),
          subPoints: [],
        },

        {
          subTitle: "Business Transfers:",
          subDescription: `Your information may be transferred as part of a merger, acquisition, or sale of all or part of our assets.`,
          subPoints: [],
        },
        {
          subTitle: "Legal Requirements:",
          subDescription: `We may disclose your information if required by law or if we believe that such action is necessary to`,
          subPoints: [
            `Comply with legal obligations`,
            `Protect and defend our rights or property`,
            `Prevent or investigate potential wrongdoing in connection with the Services`,
            `Protect the personal safety of users or the public`,
          ],
        },
      ],
    },
    {
      title: "Security of Your Information",
      subData: [
        {
          subTitle: "Access and Update:",
          subDescription: `You can access and update your personal information through your account settings. If you need assistance, please contact us.`,
          subPoints: [],
        },
        {
          subTitle: "Opt-Out:",
          subDescription: `You can opt out of receiving promotional communications by following the unsubscribe instructions in those communications or by contacting us directly.`,
          subPoints: [],
        },
        {
          subTitle: "Cookies:",
          subDescription: `You can control cookie preferences through your browser settings. Please note that disabling cookies may impact your experience on our Site.`,
          subPoints: [],
        },
      ],
      description: ``,
    },
    {
      title: "Children's Privacy",
      subData: [],
      description: `Our Services are not intended for children under 13. We do not knowingly collect or solicit personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will delete it as quickly as possible.`,
    },
    {
      title: "Contact Us",
      subData: [],
      description: `If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:`,
    },
  ];

  const _handleOpenClick = () => {
    window.open("https://donerightaway.com" || "", "_blank"); // Opens in a new tab
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className={css.img_wrapper}>
        <Stack
          sx={{ height: "100%", width: "100%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography className={css.main_title}>Privacy Policy</Typography>
        </Stack>
      </div>

      <Container
        sx={{
          py: 5,
        }}
        maxWidth="xl"
      >
        {/* cards */}

        <Stack px={{ xl: 10, lg: 10, md: 10, sm: 10, xs: 3 }} my={0}>
          <Stack my={2}>
            <Typography variant="caption" className={css.light_txt}>
              Done (
              <Typography variant="caption" className={css.bold_txt}>
                "we"
              </Typography>
              ,{" "}
              <Typography variant="caption" className={css.bold_txt}>
                "our"
              </Typography>
              , or{" "}
              <Typography variant="caption" className={css.bold_txt}>
                "us"
              </Typography>
              ) respects your privacy and is committed to protecting your
              personal information. This Privacy Policy outlines how we collect,
              use, disclose, and protect your information when you use our
              website [www.done.com](http://www.done.com) (the{" "}
              <Typography variant="caption" className={css.bold_txt}>
                “Site”
              </Typography>
              ) and our services (the{" "}
              <Typography variant="caption" className={css.bold_txt}>
                “Services”
              </Typography>
              ). By accessing the Site and using our Services, you agree to the
              terms of this Privacy Policy.
            </Typography>
          </Stack>

          <Stack mx={4}>
            <ol className={css.order_lst}>
              {privacyData.map(({ title, description, subData }, i) => {
                return (
                  <li key={i}>
                    {title && (
                      <Typography className={css.main_heading}>
                        {title}
                      </Typography>
                    )}
                    {description && (
                      <Typography className={css.light_txt}>
                        {description}
                      </Typography>
                    )}
                    {subData.length > 0 && (
                      <ol
                        className={`${css.order_lst} ${css.nested_order_lst}`}
                      >
                        {subData.map(
                          ({ subTitle, subDescription, subPoints }, i) => {
                            return (
                              <li key={i} className={css.sub_data_list}>
                                {subTitle && (
                                  <Typography className={css.sub_heading}>
                                    {subTitle}
                                  </Typography>
                                )}

                                {subDescription && (
                                  <Typography className={css.light_txt}>
                                    {subDescription}
                                  </Typography>
                                )}

                                {subPoints.length > 0 && (
                                  <ol className={css.custom_ol}>
                                    {subPoints.map((points, i) => {
                                      return (
                                        <li key={i}>
                                          {points && (
                                            <Typography
                                              variant="caption"
                                              className={css.light_txt}
                                            >
                                              {points}
                                            </Typography>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ol>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ol>
                    )}
                  </li>
                );
              })}
            </ol>
          </Stack>
        </Stack>
        <Stack px={{ xl: 14, lg: 14, md: 14, sm: 14, xs: 7 }} py={3}>
          <Typography className={css.light_txt}>Privacy Officer</Typography>
          <Typography className={css.light_txt}>Done Inc.</Typography>
          <Typography className={css.light_txt}>Canada</Typography>
          <Typography variant="caption" className={css.light_txt}>
            Email:{" "}
            <Typography
              // onClick={_handleOpenClick}
              component="a"
              href="mailto:info@donerightaway.com"
              variant="caption"
              className={css.light_txt}
              sx={{
                color: `${themeOrange} !important`,
                ":hover": {
                  textDecorationLine: "underline",
                  cursor: "pointer",
                },
              }}
            >
              info@donerightaway.com
            </Typography>
          </Typography>
        </Stack>

        <Stack my={3} alignItems={"center"} sx={{ width: "100%" }}>
          {" "}
          <Divider sx={{ width: "80%" }}>
            <Typography
              variant="caption"
              sx={{ fontStyle: "italic", color: "grey" }}
            >
              End of Privacy Policy (Last Updated: April 4, 2024)
            </Typography>
          </Divider>
        </Stack>
      </Container>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
